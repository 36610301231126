<template>
    <div id="check-writing-main" class="content-wrapper">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section headerSize="lg" borderless header-only custom-title>
            <template #header-title>
                <div v-if="summary.multipleBankAccount">
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_escrow_bank_filter', id: 'drp_escrow_bank_filter' }"
                        :items="banks"
                        value-expr="companyID"
                        display-expr="companyName"
                        v-model="activeBankCompanyID"
                        :search-enabled="false"
                        width="350px"
                    />
                </div>
                <div v-else>{{title}}{{ msg }}</div>
            </template>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Add</button>
                            <ul class="dropdown-menu">
                                <li v-rq-tooltip.hover.left="localSecurity.CanAddChecks ? '' : 'Access Restricted'">
                                    <button type="button" class="dropdown-item" automation_id="btn_add_disbursement" @click="onAddCheck" :disabled="readOnly || !localSecurity.CanAddChecks || !isBankSelected">Disbursement</button>
                                </li>
                                <li v-rq-tooltip.hover.left="localSecurity.CanAddDeposits ? '' : 'Access Restricted'">
                                    <button type="button" class="dropdown-item" automation_id="btn_add_receipt" @click="onAddDeposit" :disabled="readOnly || !localSecurity.CanAddDeposits || !isBankSelected|| !isPrimaryBankSelected">Receipt</button>
                                </li>
                                <li v-rq-tooltip.hover.left="localSecurity.CanEnterEditEscrowMemo ? '' : 'Access Restricted'">
                                    <button type="button" class="dropdown-item" automation_id="btn_add_escrow_memo" @click="onAddEscrowMemo" :disabled="readOnly || !localSecurity.CanEnterEditEscrowMemo || !isBankSelected">Escrow Memo</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Manage</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item"  automation_id="btn_create_r_and_d" @click="onCreateReceiptsAndDisbursements" :disabled="readOnly || !allowCreateReceiptsAndDisbursements || !isPrimaryBankSelected" v-rq-tooltip.hover.left :title="allowCreateReceiptsAndDisbursements ? '' : 'Access Restricted'">Create Receipts &amp; Disbursements</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item"  automation_id="btn_delete_r_and_d" @click="onDeleteReceiptsAndDisbursements" :disabled="readOnly || !allowDeleteReceiptsAndDisbursements || !isPrimaryBankSelected" v-rq-tooltip.hover.left :title="allowDeleteReceiptsAndDisbursements ? '' : 'Access Restricted'">Delete Receipts &amp; Disbursements</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item"  automation_id="btn_default_settlement_stmt" @click="onSelectDefaultSettlementStatement" :disabled="readOnly || !enableSelectDefaultSettlementStatement">Default Settlement Statement</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item"  automation_id="btn_deposit_slip_maintenance" @click="onDepositSlipMaintenance" :disabled="!hasDepositSlipAccess">Deposit Slips</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item"  automation_id="btn_transfer_slip_maintenance" @click="onTransferSlipMaintenance" :disabled="!hasDepositSlipAccess">Transfer Slips</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item"  automation_id="btn_investment_acct_maintenance" @click="onInvestmentAccountMaintenance" :disabled="readOnly || !localSecurity.InvestmentAccountIndividual_ScreenAccess">Investment Accounts</button>
                                </li>
                            </ul>
                        </div>

                    </li>
                    <li class="nav-item" v-show="showPrintAll" v-rq-tooltip.hover.top="{ title: localSecurity.AllowPrintChecks ? 'Print All Checks' : 'Access Restricted' }">
                        <b-button
                            automation_id="btn_print_all_checks"
                            class="btn btn-theme me-1"
                            variant="theme"
                            @click="onPrintAllChecks"
                            :disabled="readOnly || !localSecurity.AllowPrintChecks || printableChecks.length == 0 || !isBankSelected"
                            >Print All Checks
                        </b-button>
                    </li>
                    <li class="nav-item" v-show="showPrintAll" v-rq-tooltip.hover.top="{ title: localSecurity.AllowUndoCheck ? 'Undo All Checks' : 'Access Restricted' }">
                        <b-button
                            automation_id="btn_undo_all_checks"
                            class="btn btn-theme me-1"
                            variant="theme"
                            @click="onUndoAllChecks"
                            :disabled="readOnly || !localSecurity.AllowUndoCheck || checks.length == 0 || !isBankSelected"
                            >Undo All Checks
                        </b-button>
                    </li>
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Print Reports</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_consolidated_checks" @click="onPrintReport($event, 'Consolidated Checks')" :disabled="!hasConsolidatedChecks">Consolidated Checks</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_lender_loan_breakdown" @click="onPrintReport($event, 'Lender Loan Breakdown')" :disabled="!hasReceiptsAndDisbursements" v-rq-tooltip.hover.left :title="hasReceiptsAndDisbursements ? '' : 'Create Receipts and Disbursements has not been executed'">Lender Loan Breakdown</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_outgoing_wire_request" @click="onPrintDocument($event, 'Outgoing Wire Request')" :disabled="!hasOutgoingWireRequestTemplate" v-rq-tooltip.hover.left :title="hasOutgoingWireRequestTemplate ? '' : 'Default document not set in config.'">Outgoing Wire Request</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_receipt_of_deposit" @click="onPrintReport($event, 'Receipt of Deposit')" :disabled="!hasDeposits">Receipt of Deposit</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_receipts_and_disbursements_ledger" @click="onPrintReport($event, 'Receipts and Disbursements Ledger')">Receipts and Disbursements Ledger</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="btn_print_receipts_and_disbursements_summary" @click="onPrintReport($event, 'Receipts and Disbursements Summary')">Receipts and Disbursements Summary</button>
                                </li>
                            </ul>
                        </div>

                    </li>
                </ul>
            </template>
        </rq-page-section>
        <RqTabs
            :tabs="tabItems"
            v-model="tabIndex"
            @activate-tab="onTabActivated">
            <template #ledger>
                <check-writing-ledger-list
                    :ref="tabItems[0].ref"
                    :items="ledger"
                    :item-detail="ledgerLines"
                    :bank="bank"
                    :summary="summary"
                    @update-data="onUpdateData"
                    @update-check-data="onUpdateCheckData"
                    @update-error-message="onComponentError"
                    @print-receipt-of-deposit="onPrintReceiptOfDeposit"
                />
            </template>
            <template #receipts>
                <check-writing-deposit-list
                    :ref="tabItems[1].ref"
                    :items="deposits"
                    :item-detail="depositLines"
                    :investments="investments"
                    :bank="bank"
                    :summary="summary"
                    :deposit-line-lookups="checkDepositLineLookups"
                    @update-error-message="onComponentError"
                    @add-check="onAddCheckFromDeposit"
                    @update-data="onUpdateDepositData"
                    @print-receipt-of-deposit="onPrintReceiptOfDeposit"
                />
            </template>
            <template #disbursements>
                <check-writing-check-list
                    :ref="tabItems[2].ref"
                    :items="checks"
                    :item-detail="checkLines"
                    :bank="bank"
                    :summary="summary"
                    :check-line-lookups="checkDepositLineLookups"
                    @update-error-message="onComponentError"
                    @update-data="onUpdateCheckData"
                    @update-ledger-data="onUpdateData"
                />
            </template>
        </RqTabs>
        <check-writing-popover
            container="#check-writing-main"
            :popover="popover"
            v-model:visible="popover.visible"
        />
        <check-writing-approval-popover
            container="#check-writing-main"
            :popover="approvalPopover"
            v-model:visible="approvalPopover.visible"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { useLicenseStore } from "@/store/modules/license";
    import { DateTime } from "luxon";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { ORDER_MUTATIONS, SET_PAGE_READ_ONLY, SET_CHECK_WRITING_IN_USE_PROMPT } from "@/store/mutations";
    import { DOCUMENT_ACTIONS } from "@/store/actions";
    import { DOCUMENT_MUTATIONS } from "@/store/mutations";
    import DocumentViewer from "@documents/views/DocumentViewer";
    import CheckWritingPrintForm from "../components/CheckWritingPrintForm";
    import CheckWritingPrintCoverLetterForm from "../components/CheckWritingPrintCoverLetterForm";
    import CheckWritingLedgerList from "../components/CheckWritingLedgerList";
    import CheckWritingCheckList from "../components/CheckWritingCheckList";
    import CheckWritingDepositList from "../components/CheckWritingDepositList";
    import CheckWritingApprovalPopover from "@/shared/components/rq/CheckWritingApprovalPopover";
    import CheckWritingPopover from "@/shared/components/rq/CheckWritingPopover";
    import DefaultSettlementStatement from "./DefaultSettlementStatement";
    import DepositSlipMaintenance from "./DepositSlipMaintenance";
    import InvestmentAccountMaintenance from "./InvestmentAccountMaintenance";
    import { EscrowAccount } from "@order-entry/contacts/models";
    import Seller1099Details from "@order-entry/buyer-seller/Seller1099Details";
    import { CheckPrintRequestDto, LedgerShortDto, LedgerShortLineDto, CheckShortDto, CheckWritingLedgerSummaryDto,
            DepositShortDto, CheckLineDto, CheckDepositLineLookupDto, DepositLineDto, InvestmentDto } from "../models";
    import { CheckStatus, DepositStatus, FileBalanceStatus, SettlementStatementChoice } from '../enums';
    import { GoodFundDefinitionOptions } from '../../../configuration/enums';
    import SyncReceiptsAndDisbursementsDialog from "./SyncReceiptsAndDisbursements";
    import TransferSlipMaintenance from "./TransferSlipMaintenance";
    import { UserScreenAccessLevel } from '@/shared/models/enums';
    import { DisplayAnticipatedDeposits } from "@config/enums";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";
    import { SettlementReport } from '@settlement/components/dashboard/models';
    const CHECK_WRITING_LABEL = "Check Writing";

    export default {
        name:"CheckWriting",
        components: { CheckWritingLedgerList, CheckWritingCheckList, CheckWritingDepositList, CheckWritingApprovalPopover, CheckWritingPopover },
        data () {
            return {
                tabIndex: 0,
                activeBankCompanyID: -1,
                allBanks: new EscrowAccount({companyID: 0, companyName: "[All Banks]"}),
                banks: [],
                checks: [],
                checkLines: [],
                checkDepositLineLookups: [],
                checkWritingPINEnabled: false,
                deposits: [],
                depositLines: [],
                investments: [],
                ledger: [],
                ledgerLines: [],
                errorMessage: "",
                selectedSource: null,
                popover: {
                    visible: false,
                    target: null,
                    isCheck: false,
                    item: {},
                    itemDetail: [],
                    title: ""
                },
                approvalPopover: {
                    visible: false,
                    target: null,
                    item: {}
                },
                itemKey: "depositID",
                tabItems: [
                    { title: "Ledger", name: "ledger", ref: "ledgerList" },
                    { title: "Receipts", name: "receipts", ref: "receiptsList" },
                    { title: "Disbursements", name: "disbursements", ref: "disbursementsList" }
                ]
            };
        },
        created(){
            let self = this;
            self.init();
            self.fetchData();
            self.$events.on("edit-1099", self.onEdit1099);
            self.$events.on("print-checks", self.printChecks);
            self.$events.on("print-cover-letters", self.printCoverLetters);
            self.$events.on("undo-checks", self.onUndoChecks);
            self.$events.on("show-popover", self.onShowPopover);
            self.$events.on("show-approval-popover", self.onShowApprovalPopover);
            self.$events.on("sync-data", self.onSyncData);
        },
        beforeUnmount () {
            this.$events.off("edit-1099", this.onEdit1099);
            this.$events.off("print-checks", this.printChecks);
            this.$events.off("print-cover-letters", this.printCoverLetters);
            this.$events.off("undo-checks", this.onUndoChecks);
            this.$events.off("show-popover", this.onShowPopover);
            this.$events.off("show-approval-popover", this.onShowApprovalPopover);
            this.$events.off("sync-data", this.onSyncData);
        },
        watch: {
            orderSummary(newValue, oldValue) {
                const isSummaryPropEqual = p => _.isEqual(_.getNumber(newValue, p, 0), _.getNumber(oldValue, p, 0));
                if(_.isEqual(newValue, oldValue) || (isSummaryPropEqual("bankCompanyID") && isSummaryPropEqual("ordersID"))) return;
                this.activeBankCompanyID = -1;//reset the activeID and let it reset on fetch
                this.fetchData();
            },
            enableSelectDefaultSettlementStatement(newValue, oldValue){
                let order = _.clone(this.order);
                order.receiptsOrDisbursementsPosted = !newValue;
                this.$store.commit(ORDER_MUTATIONS.SET_ORDER, order);
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                checkWritingInUsePrompt: state => state.orders.checkWritingInUsePrompt,
                loans: state => state.orders.loans || [],
                ordersID: state => _.parseNumber(state.orders.orderId, 0),
                order: state => state.orders.order,
                orderSummary: state => state.orders.orderSummary,
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked) || _.parseBool(state.orders.orderSummary.isEscrowLocked),
                systemDefaults: state => state.system.systemDefaults,
                user: state => state.authentication.session.user
            }),
            allowPrintAnticipatedDeposits() {return this.localSecurity.AnticipatedDeposits === DisplayAnticipatedDeposits.Full},
            allowDeleteReceiptsAndDisbursements() { return this.localSecurity.AllowDepositDelete && this.localSecurity.AllowDeleteCheck && !this.orderSummary.receiptsOrDisbursementsPosted; },
            allowCreateReceiptsAndDisbursements(){ return this.localSecurity.CanAddChecks && this.localSecurity.CanAddDeposits && this.receiptsAndDisbursementsAvailability.canCreate;},
            bank() { return _.find(this.banks, ["companyID", this.activeBankCompanyID]) || this.allBanks; },
            bankCompanyName() { return _.get(this, "order.bankCompanyName", ""); },
            defaultTab() { return _.getNumber(this, "$route.params.tab", 0); },
            launchSync() { return _.getBool(this, "$route.params.launchSync", false); },
            enableSelectDefaultSettlementStatement() {
                let hasIssuedChecks = _.some(this.checks, ["checkStatus", CheckStatus.Issued]);
                let hasBookedDeposits = _.some(this.deposits, d => (d.depositStatus === DepositStatus.Booked) && !_.includes(["Earnest Money", "Option Fee"], d.description));
                return !hasIssuedChecks && !hasBookedDeposits;
            },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            hasEscrowAccount() { return _.get(this, "order.bankCompanyID", 0) > 0; },
            hasReceiptsAndDisbursements() { return _.getBool(this, "order.hasReceiptsAndDisbursements", false); },
            hasOutgoingWireRequestTemplate() { return _.getNumber(this, "systemDefaults.wireOutRequestDocumentTemplateID", 0) > 0; },
            isFileBalanced() { return _.get(this.summary, "balanceStatus", -1) != FileBalanceStatus.NotBalanced; },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowChecksWhenUnbalanced",
                    "AllowDeleteCheck",
                    "AllowDepositDelete",
                    "AllowDisbursementWithoutGoodFunds",
                    "AllowFixSynchProblems",
                    "AllowPrintChecks",
                    "AllowUndoCheck",
                    "AllowRecreateReceiptsAndDisbursements",
                    "AnticipatedDeposits",
                    "CanAddChecks",
                    "CanAddDeposits",
                    "CanChangeEscrowAcct",
                    "CanEnterEditEscrowMemo",
                    "ForcePayeeOnChecks",
                    "InvestmentAccountIndividual_ScreenAccess",
                    "DepositSlip_ScreenAccess",
                    "SecurityPinRequired"
                   ]);
            },
            hasDepositSlipAccess(){ return this.localSecurity.DepositSlip_ScreenAccess === UserScreenAccessLevel.Full || this.localSecurity.DepositSlip_ScreenAccess === UserScreenAccessLevel.Read; },
            filteredChecks() { return _.isEqual(this.activeBankCompanyID, 0) ? _.clone(this.checks) : _.filter(_.clone(this.checks), ["bankCompanyID", this.activeBankCompanyID]); },
            filteredDeposits() { return _.isEqual(this.activeBankCompanyID, 0) ? _.clone(this.deposits) : _.filter(_.clone(this.deposits), ["bankCompanyID", this.activeBankCompanyID]); },
            filteredLedger() { return _.isEqual(this.activeBankCompanyID, 0) ? _.clone(this.ledger) : _.filter(_.clone(this.ledger), ["bankCompanyID", this.activeBankCompanyID]); },
            printableChecks() {return _.filter(_.clone(this.filteredChecks), c => c.isTransfer == false && c.isWire == false && c.amount > 0 && _.includes([CheckStatus.None, CheckStatus.Approved], c.checkStatus));},
            undoableChecks() { return _.filter(_.clone(this.filteredChecks), c => c.isTransfer == false && c.isWire == false && c.amount > 0 && c.checkStatus == CheckStatus.Issued); },
            readOnly() { return this.isReadOnly || this.isFileLocked || _.get(this, "summary.readOnly", true); },
            isBankSelected() { return !_.isEqual(this.activeBankCompanyID, 0); },
            isPrimaryBankSelected() { return _.isEqual(this.activeBankCompanyID, this.orderSummary.bankCompanyID); },
            isEscrowLocked() { return this.orderSummary.isEscrowLocked; },
            title() { return _.get(this, "order.bankCompanyName", ""); },
            showPrintAll() { return this.tabIndex == 0 || this.tabIndex == 2; },
            canSync(){ return _.parseBool(this.receiptsAndDisbursementsAvailability.canSync); },
            receiptsAndDisbursementsAvailability(){return _.get(this, "orderSummary.receiptsAndDisbursementsAvailability");},
            isInvestmentAccountAccessible(){return this.securitySettings.getAccessLevel("Commitment") === UserScreenAccessLevel.Read;},
            showAnticipatedDeposits(){ return this.localSecurity.AnticipatedDeposits !== DisplayAnticipatedDeposits.None;},
            summary(){ return _.find(this.orderSummary.bankLedgers, ["bankCompanyID", this.activeBankCompanyID]) || new CheckWritingLedgerSummaryDto();},
            hasConsolidatedChecks() { return _.some(this.filteredChecks, c => c.isConsolidated && c.checkStatus != CheckStatus.Void); },
            hasDeposits(){ return this.deposits.length > 0;}
        },
        methods: {
            deleteTransferCheck(checksID) {
                if(!checksID) return;
                const self = this;
                let apiPromise = self.$api.CheckWritingApi.deleteTransferCheck(self.ordersID, [checksID]);
                self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.onUpdateCheckData(data);
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            fetchData() {
                const self = this;
                if (!self.hasEscrowAccount) return;
                let apiPromise = self.$api.CheckWritingApi.getData(self.ordersID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.setData(result);
                        if(self.receiptsAndDisbursementsAvailability.settlementStatementSelections.length === 1)
                            self.selectedSource = self.receiptsAndDisbursementsAvailability.settlementStatementSelections[0];
                        self.setApprovalMessage(result);
                        self.setCheckWritingInUse();
                        if (self.launchSync && self.canSync) {
                            self.onSyncData();
                            self.$route.params.launchSync = false;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Check Writing Data.` });
                    });
            },

            init(){
                this.tabs = {Ledger: 0, Deposits: 1, Checks: 2, Investments: 3};
                this.tabIndex = this.defaultTab;
                const licenseStore = useLicenseStore();
                this.checkWritingPINEnabled = licenseStore.checkFeature('checkWritingPIN') && this.localSecurity.SecurityPinRequired;

                //if no escrow accout is set, then display a modal first
                if (!this.hasEscrowAccount) {
                    //anyone can set the bank, they just cant change it if there is activity.
                    this.showEscrowBankNeededDialog();
                }
            },

            launchDocumentViewer (title) {
                const self = this;
                self.$dialog.open({
                    title: title,
                    height: "95%",
                    width: "95%",
                    resizable: false,
                    component: DocumentViewer,
                    closeOnly: true,
                    closeTitle: "Close"
                });
            },

            onAddCheck() {
                if(!this.localSecurity.CanAddChecks || !this.isBankSelected) return;
                this.tabIndex = this.tabs.Checks;
                this.$events.$emit('edit-check', {data: {checksID: 0, ordersID: this.ordersID, bankCompanyID: this.activeBankCompanyID}});
            },

            onAddCheckFromDeposit(e) {
                const self = this;
                if (e.check) {
                    this.checks.push(new CheckShortDto(e.check));
                    this.checkLines.push(new CheckLineDto(e.checkLine));
                    this.ledger =  _.map(_.concat(this.checks, this.deposits), c => new LedgerShortDto(c, self.showAnticipatedDeposits));
                    if (e.autoPrint) {
                        this.tabIndex = this.tabs.Checks;
                        this.$nextTick().then(() => {
                            this.showPrintChecksDialog([e.check.checksID], e.autoPrint);
                        });
                    }
                }
            },

            onAddDeposit(e) {
                if(!this.localSecurity.CanAddDeposits) return;
                if (e.deposit) {
                    this.deposits.push(new DepositShortDto(e.deposit));
                    this.depositLines.push(new DepositLineDto(e.depositLine));
                    this.ledger =  _.map(_.concat(this.checks, this.deposits), c => new LedgerShortDto(c, self.showAnticipatedDeposits));
                } else {
                    this.tabIndex = this.tabs.Deposits;
                    this.$events.$emit('edit-deposit', {data: {depositID: 0, ordersID: this.ordersID, bankCompanyID: this.activeBankCompanyID}});
                }
            },

            onAddEscrowMemo(e) {
                if(!this.localSecurity.CanEnterEditEscrowMemo) return;
                this.showEscrowMemoDialog();
            },

            onComponentError(errorMessage) {
                this.errorMessage = errorMessage;
            },

            onDepositSlipMaintenance(e) {
                if(!this.hasDepositSlipAccess) return;
                this.showDepositSlipMaintenanceDialog();
            },

            onEdit1099(buyerSellerID) {
                this.showSeller1099Dialog(buyerSellerID);
            },

            onTransferSlipMaintenance(e) {
                if(!this.hasDepositSlipAccess) return;
                this.showTransferSlipMaintenanceDialog();
            },

            onCreateReceiptsAndDisbursements(e) {
                const self = this;
                if(!self.allowCreateReceiptsAndDisbursements) return;
                if(self.receiptsAndDisbursementsAvailability.canCreate){
                    if(self.receiptsAndDisbursementsAvailability.settlementStatementSelections.length === 0){
                        self.errorMessage = "No settlement statements exist on this file to create receipts and disbursements";
                    }
                    else if(self.selectedSource){
                        self.checkCanCombineFunds(self.selectedSource);
                    }
                    else{
                        self.showCreateReceiptsAndDisbursementsSettlementStatementSelectionDialog();
                    }
                }
                else{
                    self.errorMessage = "Receipts and Disbursements have already been created on this file";
                }
            },

            onPrintDocument(e, docName) {
                const self = this;
                if (!self.hasOutgoingWireRequestTemplate) {
                    self.errorMessage = "Default document not set in config. Unable to print document.";
                    return;
                }
                if (docName == 'Outgoing Wire Request')
                {
                    let storePromise = self.$store.dispatch(DOCUMENT_ACTIONS.ADD_DOC_TO_ORDER, { documentTemplateID: _.getNumber(self, "systemDefaults.wireOutRequestDocumentTemplateID", 0), clearState: true });
                    // Set LoanID for document generation
                    self.$store.commit(DOCUMENT_MUTATIONS.SET_LOAN_ID, self.loans[0].loanID);
                    self.$rqBusy.wait(storePromise, {topLevel: true})
                        .then(result => {
                            self.launchDocumentViewer('Outgoing Wire Request');
                        });
                }
            },

            onPrintReport(e, reportName) {
                const self = this;
                let reportOptions = new ReportOptionsDto();
                if (reportName == 'Consolidated Checks')
                {
                    reportOptions = new ReportOptionsDto({
                        title: 'Consolidated Checks by File',
                        reportPath: 'System Reports/File Specific/Consolidated Checks by File',
                        parameters: {
                            p_OrdersID: self.ordersID,
                        },
                    });
                }
                if (reportName == 'Lender Loan Breakdown')
                {
                    reportOptions = SettlementReport.reportOptionsLLBreakdown(this.ordersID);
                }
                if (reportName == 'Receipt of Deposit')
                {
                    self.onPrintReceiptOfDeposit(self.deposits);
                    return;
                }
                if (reportName == 'Receipts and Disbursements Ledger')
                {
                    reportOptions = new ReportOptionsDto({
                        title: 'Receipts and Disbursements Ledger',
                        reportPath: 'System Reports/File Specific/Receipts and Disbursements Ledger',
                        parameters: {
                            p_OrderIDs: [self.ordersID],
                            p_IsCdf: self.summary.autoRecreateSource === SettlementStatementChoice.CDF,
                        },
                    });
                }
                //duplicate if above
                // if (reportName == 'Receipts and Disbursements Ledger')
                // {
                //     reportOptions = new ReportOptionsDto({
                //         title: 'Receipts and Disbursements Ledger',
                //         reportPath: 'System Reports/File Specific/Receipts and Disbursements Ledger',
                //         parameters: {
                //             p_OrderIDs: [self.ordersID],
                //             p_IsCdf: self.summary.autoRecreateSource === SettlementStatementChoice.CDF,
                //         },
                //     });
                // }
                if (reportName == 'Receipts and Disbursements Summary')
                {
                    reportOptions = new ReportOptionsDto({
                        title: 'Receipts and Disbursements Summary',
                        reportPath: 'System Reports/File Specific/Receipts and Disbursements Summary',
                        parameters: {
                            p_OrderIDs: [self.ordersID]
                        }
                    });
                }
                if (_.isNullOrEmpty(reportOptions.reportPath)) {
                    self.$dialog.confirm(`Print ${reportName}`, `Coming Soon`, null, null, { cancelTitle: 'Cancel', okTitle: 'OK'});
                } else {
                    _.invoke(self, "$rq.showReport", reportOptions.reportPath, reportOptions);
                }
            },

            onPrintReceiptOfDeposit(deposits){
                const self = this;
                if(deposits.length <= 0) return;
                let ids = _.map(deposits, self.itemKey);
                let needReceiptIDs = _.filter(deposits, d =>  (!d.receiptID || _.isNil(d.receiptID)));
                if (needReceiptIDs.length > 0){
                    let needIDs = _.map(needReceiptIDs, self.itemKey);
                    self.setReceiptIDs(needIDs) .then(results => {
                         self.refreshReceiptIDs(_.map(results, d => new DepositShortDto(d)));
                         self.loadDepositReport(ids);

                     })
                }else {
                    self.loadDepositReport(ids);
                }
            },

            setReceiptIDs(depositIDs){
                const self = this;
                let apiPromise = self.$api.CheckWritingApi.setReceiptIDs(depositIDs);
                return self.$rqBusy.wait(apiPromise);
            },

            refreshReceiptIDs(items){
                const self = this;
                let deposits = _.clone(self.deposits);
                let depositLines = _.clone(self.depositLines);
                _.forEach(items, function(item) {
                    let itemIndex = _.findIndex(self.deposits, [self.itemKey, _.get(item, self.itemKey)]);
                    deposits[itemIndex].receiptID = item.receiptID;
                    _.assign(deposits[itemIndex], new DepositShortDto(item));
                });
                self.deposits = deposits;
                self.ledger = _.map(_.concat(self.checks, self.deposits), c => new LedgerShortDto(c, self.showAnticipatedDeposits));
                self.$refs.receiptsList.gridInstance.refresh();
                self.$refs.ledgerList.gridInstance.refresh();
            },

            loadDepositReport(depositIDs){
                const self = this;
                var ids = depositIDs.join(",");
                let reportOptions = new ReportOptionsDto({
                        title: 'Receipt of Deposit',
                        reportPath: 'System Reports/File Specific/Receipt Of Deposit',
                        parameters: {
                            p_DepositIDs: ids
                        },
                    });
                _.invoke(self, "$rq.showReport", reportOptions.reportPath, reportOptions);
            },

            onInvestmentAccountMaintenance(e) {
                if(!this.localSecurity.InvestmentAccountIndividual_ScreenAccess) return;
                this.showInvestmentAccountMaintenanceDialog();
            },

            showCreateReceiptsAndDisbursementsSettlementStatementSelectionDialog(){
                const self = this;
                self.$dialog.promptSelect({
                    title: "Settlement Statement Selection",
                    label: "Reconciliation",
                    inputId: "drp_settlement_statement",
                    isRequired: true,
                    items: self.receiptsAndDisbursementsAvailability.settlementStatementSelections,
                    valueExpr: "description",
                    displayExpr: "description",
                    onOk: async e => {
                        if(e.selectedValue) {
                            self.selectedSource = _.find(self.receiptsAndDisbursementsAvailability.settlementStatementSelections, b => b.description === e.selectedValue);
                            self.checkCanCombineFunds(self.selectedSource);
                        }
                        return true;
                    }
                });
            },

            checkCanCombineFunds(settlementStatementSelection){
                const self = this;
                let cancel = function (args) {
                    settlementStatementSelection.combineFunds = false;
                    self.doCreateReceiptsAndDisbursements(settlementStatementSelection);
                };
                let ok = function (args) {
                    self.errorMessage = "";
                    settlementStatementSelection.combineFunds = true;
                    self.doCreateReceiptsAndDisbursements(settlementStatementSelection);
                };
                let promise = self.$api.CheckWritingApi.canCombineFunds(self.ordersID, settlementStatementSelection).then(result => {
                    if(result){
                        self.$dialog
                            .confirm(`Combine Funds?`,
                                "Do you want to combine the buyer funds from secondary loan(s) to the first loan for a single deposit?",
                                ok, cancel, { cancelTitle: 'No', okTitle: 'Yes'}
                    );
                    }
                    else{
                        self.doCreateReceiptsAndDisbursements(settlementStatementSelection);
                    }
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error creating receipts and disbursements.` });
                });
                self.$rqBusy.wait(promise);
                return promise;
            },

            doCreateReceiptsAndDisbursements(settlementStatementSelection){
                const self = this;
                let promise = self.$api.CheckWritingApi.doCreateReceiptsAndDisbursements(self.ordersID, settlementStatementSelection);
                self.$rqBusy.wait(promise)
                    .then(result => {
                        self.setData(result);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error creating receipts and disbursements.` });
                    });
            },

            onDeleteReceiptsAndDisbursements(e) {
                const self = this;
                if(!self.allowDeleteReceiptsAndDisbursements) return;
                let ok = function (args) {
                    self.errorMessage = "";
                    self.canDeleteReceiptsAndDisbursements();
                };
                if(self.summary.multipleBankAccount) {
                    self.$dialog
                        .confirm("Confirm Delete",
                            "All checks and deposits will be deleted. All items will default back to the Primary Escrow Account",
                            ok, null, { cancelTitle: "No", okTitle: "Yes"}
                    );
                }
                else {
                    self.$dialog
                        .confirm("Confirm Delete",
                            "Are you sure you want to delete all Receipts and Disbursements?",
                            ok, null, { cancelTitle: "No", okTitle: "Yes"}
                        );
                }
            },

            canDeleteReceiptsAndDisbursements(){
                const self = this;
                let promise = self.$api.CheckWritingApi.canDeleteReceiptsAndDisbursements(self.ordersID);
                self.$rqBusy.wait(promise)
                    .then(result => {
                        if(result.success && result.errors.length === 0 && result.warnings.length === 0)
                            self.doDeleteReceiptsAndDisbursement();
                        else if(!result.success){
                            self.errorMessage = result.errors[0];
                        }
                        else{
                            self.confirmDeleteReceiptsAndDisbursements(result.warnings);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error deleting receipts and disbursements.` });
                    });
            },

            confirmDeleteReceiptsAndDisbursements(warnings){
                const self = this;
                let ok = function (args) {
                    self.errorMessage = "";
                    self.doDeleteReceiptsAndDisbursements();
                };
                self.$dialog
                    .confirm("Delete of Receipts and Disbursements Warning",
                        warnings.join(" "),
                        ok, null, { cancelTitle: "No", okTitle: "Yes"}
                    );
            },

            doDeleteReceiptsAndDisbursement(){
                const self = this;
                let promise = self.$api.CheckWritingApi.doDeleteReceiptsAndDisbursements(self.ordersID);
                self.$rqBusy.wait(promise)
                    .then(result => {
                        self.setData(result);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Error deleting receipts and disbursements.");
                    });
            },

            onSyncData(e) {
                const self = this;
                if(!self.selectedSource) return;
                self.getSyncData(self.selectedSource);
            },

            getSyncData(settlementStatementSelection){
                const self = this;
                let promise = self.$api.CheckWritingApi.getSyncData(self.ordersID, settlementStatementSelection);
                self.$rqBusy.wait(promise)
                    .then(result => {
                        self.showSyncDialog(result);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Error getting sync data.");
                    });
            },

            printChecks(checks) {
                if(!_.isArray(checks) || _.size(checks) == 0) return;
                const self = this;
                self.errorMessage = "";
                let balance = 0;
                let whichBalance = '';
                let hasIncompletePayeeInfo = _.some(_.filter(checks, (check) => check.checkStatus != CheckStatus.Hold), c => _.isNullOrEmpty(c.payee) || _.isNullOrEmpty(c.payeeAddress1) || _.isNullOrEmpty(c.payeeCity) || _.isNullOrEmpty(c.payeeState) || _.isNullOrEmpty(c.payeeZip));
                if (self.checkWritingPINEnabled && self.localSecurity.AllowPrintChecks && (_.isNullOrEmpty(self.user.securityPin) || !self.user.hasSignature)) {
                    self.errorMessage = `Cannot print checks until your signature & PIN are created in Account Settings`;
                    return;
                }
                if (hasIncompletePayeeInfo && self.localSecurity.ForcePayeeOnChecks) {
                    self.errorMessage = `Some checks do not have a full name or address and will not be disbursed.`;
                    return;
                }
                if (_.some(checks, ["payee", "No Payee Indicated"])) {
                    _.pullAll(checks, _.filter(checks, ["payee", "No Payee Indicated"]));
                    self.errorMessage = `Some checks do not have a Payee and will not be disbursed.`;
                }
                if (_.some(checks, ["checkStatus", CheckStatus.Hold])) {
                    _.pullAll(checks, _.filter(checks, ["checkStatus", CheckStatus.Hold]));
                    self.errorMessage = `Some checks are on Hold and will not be disbursed.`;
                }
                if (self.localSecurity.AnticipatedDeposits == DisplayAnticipatedDeposits.Full) {
                    balance = self.summary.anticipatedBalance;
                    whichBalance = 'Anticipated';
                } else {
                    balance = self.summary.preDisbursementBalance;
                    whichBalance = 'Pre-Disbursement';
                }
                if (self.localSecurity.AllowChecksWhenUnbalanced) {
                    balance += self.summary.anticipatedDisbursements;
                }
                if (balance != 0 && !self.localSecurity.AllowChecksWhenUnbalanced) {
                    self.errorMessage = `Unable to Print Checks - ${whichBalance} Balance Must be Zero.`;
                    return;
                }
                if (_.some(checks, ["hasValidAccountCode", false])) {
                    self.errorMessage = `Some checks require an Account Code.`;
                    return;
                }
                if (checks.length == 0) {
                    self.errorMessage = `No checks are eligible to be printed.`;
                    return;
                }
                let checksIDs = _.map(checks, "checksID");
                let checkTotal = _.sumBy(checks, "amount");
                //removed for RQO-16093, should be taken care of below
                // if (self.systemDefaults.goodFundDefinition != GoodFundDefinitionOptions.All && !self.localSecurity.AllowDisbursementWithoutGoodFunds && balance < checkTotal) {
                //     self.errorMessage = `Unable to Print Check${checksIDs.length > 1 ? 's' : ''} - Check Total Exceeds Available Funds.`;
                //     return;
                // }
                //removed for RQO-18901.  This rule is already enforced correctly above at line 760 making this redundant and it's also incorrect as the balance includes the checkTotal. Comparing check total to balance is incorrect.  When the file is
                //in balance the balance will be zero and check total will always be > balance in that scenario
                //if (!self.localSecurity.AllowChecksWhenUnbalanced && checkTotal > balance) {
                //    self.errorMessage = "Unable to print one of more checks, value of selected check(s) exceeds account balance.";
                //    return;
                //}
                let goodFundDepositTotal = 0;
                let now = DateTime.fromISO(DateTimeHelper.nowTenantStartOfDay());
                let reconciledDeposits = d => !_.isNil(d.reconciliationDate) && now.diff(DateTime.fromISO(d.reconciliationDate), "days").days >= 0;
                let verifiedDeposits = d => !_.isNil(d.depositVerified) && now.diff(DateTime.fromISO(d.depositVerified), "days").days >= 0;
                switch (self.systemDefaults.goodFundDefinition) {
                    case GoodFundDefinitionOptions.All:
                        goodFundDepositTotal = _.sumBy(self.deposits, "amount");
                        break;
                    case GoodFundDefinitionOptions.UseReconDateAndVerifiedDate://name is misleading, it's actually Recon Date OR Verified Date
                        goodFundDepositTotal = _.sumBy(_.filter(self.deposits, d => reconciledDeposits(d) || verifiedDeposits(d)), "amount");
                        break;
                    case GoodFundDefinitionOptions.UseReconDateOnly:
                        goodFundDepositTotal = _.sumBy(_.filter(self.deposits, d => reconciledDeposits(d)), "amount");
                        break;
                    case GoodFundDefinitionOptions.UseVerifiedDateOnly:
                        goodFundDepositTotal = _.sumBy(_.filter(self.deposits, d => verifiedDeposits(d)), "amount");
                        break;
                    default:
                        goodFundDepositTotal = 0;
                        self.errorMessage = 'Invalid Good Funds Setting.';
                        return;
                }
                if (!self.localSecurity.AllowDisbursementWithoutGoodFunds && checkTotal > goodFundDepositTotal) {
                    self.errorMessage = 'The amount of the requested disbursements is greater than the amount of current Available Funds.';
                    return;
                }
                self.showPrintChecksDialog(checksIDs);
            },

            onPrintAllChecks(e) {
                if(this.readOnly || !this.localSecurity.AllowPrintChecks || this.printableChecks.length == 0) return;
                let holdChecks = _.filter(this.filteredChecks, ["checkStatus", CheckStatus.Hold]);
                let checks = _.concat(this.printableChecks, holdChecks);//add back checks on hold so the warning shows
                this.printChecks(checks);
            },

            printCoverLetters(check) {
                this.showPrintCoverLettersDialog(check);
            },

            onSelectDefaultSettlementStatement(e) {
                if(!this.enableSelectDefaultSettlementStatement) return;
                this.showDefaultSettlementStatementDialog();
            },

            onShowApprovalPopover(data) {
                this.approvalPopover = _.assign({}, this.approvalPopover, data);
            },

            onShowPopover(data) {
                this.popover = _.assign({}, this.popover, data);
            },

            onTabActivated(e) {
                this.refresh(e.tab.ref);
            },

            onUndoAllChecks(e) {
                const self = this;
                if(self.readOnly || !self.localSecurity.AllowUndoCheck || self.checks.length == 0) return;
                if (self.undoableChecks.length == 0) {
                    self.errorMessage = "No checks are eligible to be undone.";
                    return;
                }
                let ok = function (args) {
                    self.errorMessage = "";
                    let apiPromise = self.$api.CheckWritingApi.undoAllChecks(self.ordersID);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.$store.commit(ORDER_MUTATIONS.SET_ORDER_SUMMARY, result.orderSummary);
                            self.checks = _.map(result.checks, c => new CheckShortDto(c));
                            self.checkLines = _.map(result.checkLines, c => new CheckLineDto(c));
                            self.ledger =  _.map(_.concat(self.checks, self.deposits), c => new LedgerShortDto(c, self.showAnticipatedDeposits));
                            self.ledgerLines = _.map(_.concat(self.checkLines, self.depositLines), c => new LedgerShortLineDto(c));

                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: "Error undoing all Checks." });
                            return true;
                        })
                        .finally(() => {
                            self.refresh('disbursementsList');
                            self.refresh('ledgerList');
                        });
                }

                self.$dialog.confirm("Confirm Undo All", "Are you sure you want to undo all Checks?", ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onUndoChecks(checkIDs) {
                if(!checkIDs) return;
                const self = this;
                let apiPromise = self.$api.CheckWritingApi.undoChecks(self.ordersID, checkIDs);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.onUpdateCheckData(result);
                    }).catch(error => {
                        self.$toast.error({ message: `${error.errorMessage}` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onUpdateData(data) {
                this.$store.commit(ORDER_MUTATIONS.SET_ORDER_SUMMARY, data.orderSummary);
                this.checks = _.map(data.checks, c => new CheckShortDto(c));
                this.checkLines = _.map(data.checkLines, c => new CheckLineDto(c));
                this.deposits = _.map(data.deposits, d => new DepositShortDto(d));
                this.depositLines = _.map(data.depositLines, d => new DepositLineDto(d));
                this.ledger =  _.map(_.concat(this.checks, this.deposits), c => new LedgerShortDto(c, self.showAnticipatedDeposits));
                this.ledgerLines = _.map(_.concat(this.checkLines, this.depositLines), c => new LedgerShortLineDto(c));
                this.setApprovalMessage(data);
            },

            onUpdateCheckData(data) {
                this.$store.commit(ORDER_MUTATIONS.SET_ORDER_SUMMARY, data.orderSummary);
                this.checks = _.map(data.checks, c => new CheckShortDto(c));
                this.checkLines = _.map(data.checkLines, c => new CheckLineDto(c));
                this.ledger =  _.map(_.concat(this.checks, this.deposits), c => new LedgerShortDto(c, self.showAnticipatedDeposits));
                this.ledgerLines = _.map(_.concat(this.checkLines, this.depositLines), c => new LedgerShortLineDto(c));
                this.setApprovalMessage(data);
            },

            onUpdateDepositData(data) {
                this.$store.commit(ORDER_MUTATIONS.SET_ORDER_SUMMARY, data.orderSummary);
                this.deposits = _.map(data.deposits, d => new DepositShortDto(d));
                this.depositLines = _.map(data.depositLines, d => new DepositLineDto(d));
                this.ledger =  _.map(_.concat(this.checks, this.deposits), c => new LedgerShortDto(c, self.showAnticipatedDeposits));
                this.ledgerLines = _.map(_.concat(this.checkLines, this.depositLines), c => new LedgerShortLineDto(c));
                if (_.size(data.investments) > 0) this.investments = _.map(data.investments, i => new InvestmentDto(i));
            },

            saveEscrowAccount(bankCompanyID){
                const self = this;
                let apiPromise = self.$api.OrdersApi.saveEscrowAccount(self.ordersID, bankCompanyID);
                return self.$rqBusy.wait(apiPromise);
            },

            saveEscrowMemo(memo){
                const self = this;
                let apiPromise = self.$api.OrdersApi.saveEscrowMemo(self.ordersID, memo);
                return self.$rqBusy.wait(apiPromise);
            },

            saveAutoRecreateSource(autoRecreateSource){
                const self = this;
                let apiPromise = self.$api.OrdersApi.saveAutoRecreateSource(self.ordersID, autoRecreateSource);
                return self.$rqBusy.wait(apiPromise);
            },

            setApprovalMessage(data){
                const self = this;
                if(_.parseBool(data.needsApprover)) {
                    self.errorMessage = 'Approver required, contact your administrator';
                } else self.errorMessage = "";
            },

            setData(result) {
                const self = this;
                self.$store.commit(ORDER_MUTATIONS.SET_ORDER_SUMMARY, result.orderSummary);
                if (_.size(result.banks) == 1) {
                    self.banks = _.map(result.banks, b => new EscrowAccount(b));
                } else {
                    self.banks = _.map(_.sortBy(_.concat(result.banks, self.allBanks), ["companyID", "companyName"]), b => new EscrowAccount(b));
                }
                if (self.activeBankCompanyID < 0) self.activeBankCompanyID = self.orderSummary.bankCompanyID;
                self.checks = _.map(result.checks, c => new CheckShortDto(c));
                self.checkLines = _.map(result.checkLines, c => new CheckLineDto(c));
                self.deposits = _.map(result.deposits, d => new DepositShortDto(d));
                self.depositLines = _.map(result.depositLines, d => new DepositLineDto(d));
                self.investments = _.map(result.investments, c => new InvestmentDto(c));
                self.ledger = _.map(_.concat(self.checks, self.deposits), c => new LedgerShortDto(c, self.showAnticipatedDeposits));
                self.ledgerLines = _.map(_.concat(self.checkLines, self.depositLines), c => new LedgerShortLineDto(c));
                self.checkDepositLineLookups = _.map(result.checkDepositLineLookups, c => new CheckDepositLineLookupDto(c));
                let order = _.clone(self.order);
                order.autoRecreateSource = result.orderSummary.autoRecreateSource;
                order.hasReceiptsAndDisbursements = result.orderSummary.hasEscrowChecksOrDeposits;
                order.receiptsOrDisbursementsPosted = result.orderSummary.receiptsOrDisbursementsPosted;
                self.$store.commit(ORDER_MUTATIONS.SET_ORDER, order);
            },

            setCheckWritingInUse(e) {
                const self = this;
                let isConsolidatedFile = _.getBool(self.orderSummary, "isConsolidatedFile");
                if (isConsolidatedFile) return;
                let isCheckWritingInUse = _.isEqual(_.get(self.orderSummary, "fileInUsePage"), CHECK_WRITING_LABEL);
                let allowOpenFileInUse = _.getBool(self.user, "allowOpenFileInUse");//only show this msg to users who have the ability to open files in use
                if (isCheckWritingInUse && allowOpenFileInUse) {
                    //if they already acknowledged the prompt, just set read-only, otherwise show the prompt
                    if (self.checkWritingInUsePrompt) {
                        self.showCheckWritingInUsePrompt();
                    } else {
                        self.$store.commit(SET_PAGE_READ_ONLY, true);
                    }
                }
            },

            async showSeller1099Dialog (buyerSellerID) {
                const self = this;
                self.$rqBusy.startWait();
                let sellers = [];
                try {
                    sellers = await self.$api.OrdersApi.getSellers(self.ordersID);
                } catch (err) {
                    console.log(err);
                    self.$toast.success("Error trying to fetch Seller 1099 Info.");
                    return;
                }
                self.$rqBusy.endWait();
                let seller = _.find(sellers, ["buyerSellerID", buyerSellerID]);
                let onOk = (e) => {
                    let form = e.component;
                    return form.save()
                        .then((result) => {
                            self.refresh('disbursementsList');
                            self.refresh('ledgerList');
                            if (result) self.$toast.success("Successfully updated Seller 1099 Information.");
                            return true;
                        })
                        .catch(error => {
                            e.component.errorMessage = e.errorMessage;
                            return false;
                        });
                };
                let onCancel = (e) => {
                    let form = e.component;
                    if (form.hasChanges) {
                        let onDiscard = function (args) {
                            self.refresh('disbursementsList');
                            self.refresh('ledgerList');
                            return true;
                        };
                        let onSave = (e) => {
                            return  form.save()
                                    .then((result) => {
                                        self.refresh('disbursementsList');
                                        self.refresh('ledgerList');
                                        if (result) self.$toast.success("Successfully updated Seller 1099 Information.");
                                        return true;
                                    })
                                    .catch(error => {
                                        e.component.errorMessage = e.errorMessage;
                                        return false;
                                    });
                        };
                        if(!form.hasFormError){
                            self.$dialog.confirm("Confirm Cancel",
                            `You have pending changes, do you want to save or discard changes?`,
                            onSave,
                            onDiscard,
                            { cancelTitle: 'Discard', okTitle: 'Save'});
                        }
                    }
                };

                self.$dialog.open({
                    title: "1099 Information",
                    width: "75%",
                    height: "270",
                    resizable: false,
                    component: Seller1099Details,
                    props: {
                        buyerSeller: seller,
                        isModal: true
                    },
                    onOk: onOk,
                    onCancel: onCancel
                });
            },

            showCheckWritingInUsePrompt(e) {
                const self = this;
                let onOK = (e) => {
                    self.$store.commit(SET_PAGE_READ_ONLY, true);
                    self.$store.commit(SET_CHECK_WRITING_IN_USE_PROMPT, false);
                    return true;
                };
                let msg = `Check Writing in File ${self.order.gfNo} is currently in use by ${_.get(this, "orderSummary.fileInUseBy", "unknown")}. Check writing can only have one user in edit-mode. Check Writing will open in Read-Only mode.`;
                self.$dialog.open({
                    title: "Check Writing In Use Warning",
                    width: "500",
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    component: {
                        name: "CheckWritingInUsePrompt",
                        template:   `<div class="content-wrapper">
                                        <div class="rq-container mt-2">
                                            <div class="row">
                                                <div class="col">
                                                    <p>${msg}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>`,
                    },
                    closeOnEsc: false,
                    okOnly: true,
                    onOk: onOK,
                    props: { }
                });
            },

            showDepositSlipMaintenanceDialog () {
                const self = this;
                let onOk = (e) => {
                    return e.component.saveDetail()
                        .then(() => {
                            //pull back a refech copy of the data since the DepositSlipID might have changed
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                            e.component.errorMessage = e.errorMessage;
                            return false;
                        });
                };

                self.$dialog.open({
                    title: "Deposit Slips",
                    width: "95%",
                    height: "85%",
                    resizable: false,
                    component: DepositSlipMaintenance,
                    onOk: onOk
                });
            },

            showTransferSlipMaintenanceDialog () {
                const self = this;
                let onOk = (e) => {
                    return e.component.saveDetail()
                        .then(() => {
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                            e.component.errorMessage = e.errorMessage;
                            return false;
                        });
                };

                self.$dialog.open({
                    title: "Transfer Slips",
                    width: "95%",
                    height: "85%",
                    resizable: false,
                    component: TransferSlipMaintenance,
                    onOk: onOk
                });
            },

            showEscrowMemoDialog() {
                const self = this;
                let originalMemo = self.order.escrowMemo;
                self.$dialog.promptInput({
                    title: "Escrow Memo",
                    value: originalMemo,
                    isRequired: false,
                    multiline: true,
                    okTitle: "Save",
                    disabled: self.isEscrowLocked || self.isFileLocked,
                    onOk: e => {
                        let memo = e.component.inputValue;
                        if (_.isEqual(originalMemo, memo)) {
                            self.$toast.info("No Changes Detected.");
                            return true;
                        }
                        return self.saveEscrowMemo(memo)
                            .then(() => {
                                let order = _.clone(self.order);
                                order.escrowMemo = memo;
                                self.$store.commit(ORDER_MUTATIONS.SET_ORDER, order);
                                return true;
                            })
                            .catch(error => {
                                self.$toast.error("Error saving Escrow Memo.");
                                console.error(error);
                                return error;
                            });
                    }
                });
            },

            showEscrowBankNeededDialog() {
                const self = this;
                let originalValue = _.parseNumber(self.order.bankCompanyID, 0);
                let items = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_ACCOUNTS);
                let onOk = (e) => {
                    let newValue = _.getNumber(e, "selectedValue", 0);
                    if (_.isEqual(0, newValue)) {
                        return false;
                    }
                    if (_.isEqual(originalValue, newValue)) {
                        self.$toast.info({ message: "No Changes Detected." });
                        self.$router.push(`/order/${self.ordersID}`);
                        return true;
                    }
                    return self.saveEscrowAccount(newValue)
                        .then(() => {
                            self.activeBankCompanyID = newValue;
                            let order = _.clone(self.order);
                            order.bankCompanyID = newValue;
                            order.bankCompanyName = self.lookupHelpers.getLookupItemName(self.lookupItems.ESCROW_ACCOUNTS, newValue);
                            self.$store.commit(ORDER_MUTATIONS.SET_ORDER, order);
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error("Error saving Escrow Account.");
                            console.error(error);
                            return error;
                        })
                };
                let onCancel = (e) => {
                    self.$router.push(`/order/${self.ordersID}/oe`);
                };
                self.$dialog.promptSelect({
                    title: `Escrow Account : ${self.orderSummary.fileNumber}`,
                        label: "Escrow Account",
                        inputId: "cmb_reconciliation_id",
                        isRequired: true,
                        items: items,
                        valueExpr: "id",
                        displayExpr: "name",
                        alerts: [],
                        onOk: onOk,
                        onCancel: onCancel
                    });
            },

            showDefaultSettlementStatementDialog() {
                const self = this;
                let originalValue = self.summary.autoRecreateSource;
                let defaultOptions = SettlementStatementChoice.lookupItems;
                let onOk = (e) => {
                    let newValue = e.component.autoRecreateSource;
                    if (_.isEqual(originalValue, newValue)) {
                        self.$toast.info({ message: `No Changes Detected.` });
                        return true;
                    }
                    return self.saveAutoRecreateSource(newValue)
                        .then(() => {
                            let order = _.clone(self.order);
                            order.autoRecreateSource = newValue;
                            self.$store.commit(ORDER_MUTATIONS.SET_ORDER, order);
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error("Error saving Default Settlement Statement.");
                            console.error(error);
                            return error;
                        })
                };
                self.$dialog.open({
                    title: `Default Settlement Statement : ${self.order.gfNo}`,
                    width: 600,
                    adaptive: true,
                    component: DefaultSettlementStatement,
                    onOk: onOk,
                    okTitle: "Save",
                    props: {originalValue: originalValue, defaultOptions: defaultOptions},
                });
            },

            showInvestmentAccountMaintenanceDialog () {
                const self = this;
                let onOk = (e) => {
                    self.fetchData();
                };

                self.$dialog.open({
                    title: "Manage Investment Accounts",
                    height: "95%",
                    width: "95%",
                    okTitle: "Close",
                    resizable: false,
                    okOnly: true,
                    component: InvestmentAccountMaintenance,
                    onOk: onOk,
                    props: {ordersID: self.ordersID, bankCompanyName: self.order.bankCompanyName}
                });
            },

            showPrintChecksDialog(checksIDs, autoPrint = false) {
                const self = this;
                let request = new CheckPrintRequestDto({ordersID: self.ordersID, checksIDs: checksIDs});
                let onCancel = (e) => {
                    //if a check is autoPrinted, and they decide to cancel the process then the check must be deleted.
                    if (autoPrint) {
                        self.deleteTransferCheck(checksIDs[0]);
                    }
                }
                let onOk = (e) => {
                    self.errorMessage = "";
                    let form = e.component;
                    form.validate();
                    if (!form.isValid()) {
                       return false;
                    }
                    form.printChecks()
                        .then(data => {
                            self.onUpdateCheckData(data);
                        }).catch(e => {
                            self.errorMessage = e.errorMessage;
                            return true;
                        })
                        .finally(() => {
                            self.refresh('disbursementsList');
                            self.refresh('ledgerList');
                            return true;
                        });
                };
                self.$dialog.open({
                    title: `Print ${checksIDs.length} Checks`,
                    height: "auto",
                    width: "800",
                    resizable: false,
                    adaptive: true,
                    scrollable: false,
                    closeOnEsc: true,
                    component: CheckWritingPrintForm,
                    onOk: onOk,
                    onCancel: onCancel,
                    props: {
                        item: request,
                        bank: self.bank,
                        summary: self.summary,
                        lastFinalizedReconDate: self.order.lastFinalizedReconDate,
                        checkWritingPINEnabled: self.checkWritingPINEnabled
                    },
                });
            },

            showPrintCoverLettersDialog(check) {
                const self = this;
                let onCancel = (e) => {};
                let onOk = (e) => {
                    let form = e.component;

                    form
                    .printCoverLetter()
                    .then((data) => {})
                    .catch((e) => {
                        self.errorMessage = e.errorMessage;
                        return true;
                    })
                    .finally(() => {
                        return true;
                    });
                };

                let apiPromise = self.$api.CheckCoverLettersApi.getCoverLettersForOrder(
                    self.ordersID
                );

                self.$rqBusy
                    .wait(apiPromise)
                    .then((result) => {
                    self.$dialog.open({
                        title: `Print Cover Letters`,
                        height: "auto",
                        width: "800",
                        resizable: false,
                        adaptive: true,
                        scrollable: false,
                        closeOnEsc: true,
                        component: CheckWritingPrintCoverLetterForm,
                        onOk: onOk,
                        onCancel: onCancel,
                        props: {
                        check: check,
                        ordersID: self.ordersID,
                        coverLetters: result,
                        },
                    });
                    })
                    .catch((error) => {
                    console.log(error);
                    self.$toast.error({
                        message: `Error loading cover letters.`,
                    });
                    });
            },

            showSyncDialog(syncData) {
                const self = this;
                self.errorMessage = "";
                if (!self.localSecurity.AllowFixSynchProblems) {
                    self.errorMessage = "Access Restricted";
                    return;
                }
                let onOk = (e) => {
                    self.receiptsAndDisbursementsAvailability.canSync = e.component.fixes.length > 0;
                    if(e.component.somethingFixed)
                        self.fetchData();
                    return true;
                };
                self.$dialog.open({
                    title: "Synchronize Receipts and Disbursements",
                    height: "95%",
                    width: 1280,
                    minWidth: 1280,
                    minHeight: 800,
                    component: SyncReceiptsAndDisbursementsDialog,
                    props: { syncData, source: self.selectedSource },
                    onOk: onOk,
                    okTitle: "Close",
                    okOnly: true
                });
            },

            refresh(name) {
                let grid = _.get(this.$refs, name);
                let hasRefresh = _.isFunction(_.get(grid, 'refresh'));
                if (grid && hasRefresh) {
                    grid.refresh();
                    _.invoke(grid, "gridInstance.hideColumnChooser");
                }
                this.popover.visible = false;
                this.approvalPopover.visible = false;
            },
        }
    }
</script>