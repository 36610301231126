<template>
    <div class="content-wrapper" id="ezjacket-policies-grid">
        <rq-banner
            :message="warningMsg"
            variant="warning"
            icon="fas fa-exclamation-triangle"
            :visible="showWarning"
            dismissable
        />
        <rq-page-section title="Starslink Order Info" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" collapsible v-if="showOrder">
            <div class="row">
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_order_id">Order Id</label>
                    <input automation_id="txt_order_id" id="txt_order_id" v-model="jacketOrderId" type="text" class="form-control" disabled/>
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_order_status">Order Status</label>
                    <input automation_id="txt_order_status" id="txt_order_status" v-model="jacketOrderStatus" type="text" class="form-control" disabled/>
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_agent_id">Agent Id</label>
                    <input automation_id="txt_agent_id" id="txt_agent_id" v-model="jacketOrderAgencyNumber" type="text" class="form-control" disabled/>
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_effective_date">Effective Date</label>
                    <input automation_id="txt_effective_date" id="txt_effective_date" v-model="jacketOrderEffectiveDate" type="text" class="form-control" disabled/>
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            title="Policy Jackets"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            @schedule-upload="onScheduleUpload"
            hide-search>
            <template #toolbar>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn variant="theme" class="btn btn-sm btn-theme ms-2" @click="onAddOrder" v-if="canAdd">Add</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn variant="theme" class="btn btn-sm btn-theme ms-2" @click="onEditOrder" v-if="canEdit">Edit</b-btn>
                    </li>
                    <li class="nav-item" v-rq-tooltip.hover.top="{ title: canRemit ? '' : 'One or more Policies does not have Schedules' }">
                        <b-btn variant="theme" class="btn btn-sm btn-theme ms-2" @click="onRemitOrder" v-if="showRemit" :disabled="!canRemit">Remit</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn variant="theme" class="btn btn-sm btn-theme ms-2" @click="onVoidOrder" v-if="canVoid">Void</b-btn>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
        <grid-popover
            id="endorsements"
            container="#ezjacket-policies-grid"
            :popover="gridPopoverEndorsements"
            v-model:visible="gridPopoverEndorsements.visible"
        />
        <grid-popover
            id="fees"
            container="#ezjacket-policies-grid"
            :popover="gridPopoverFees"
            v-model:visible="gridPopoverFees.visible"
        />
        <grid-popover
            id="images"
            container="#ezjacket-policies-grid"
            :popover="gridPopoverImages"
            v-model:visible="gridPopoverImages.visible"
        />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import GridPopover from "@/shared/components/rq/GridPopover";
    import { EzJacketPolicyStatus, EzJacketPolicyCategory, EzJacketRemittanceStatus, EzJacketPaymentMethods } from "../enums";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import SmktEzJacketScheduleUploadDialog from "../../ezjacket/components/SmktEzJacketScheduleUploadDialog.vue";
    import SmktEzJacketRemitDialog from "../../ezjacket/components/SmktEzJacketRemitDialog.vue";
    import SmktEzJacketVoidDialog from "../../ezjacket/components/SmktEzJacketVoidDialog.vue";

    export default {
        name: "PolicyJacketList",
        components: { GridPopover },
        mixins: [ GridSystemLookupMixin ],
        data: function() {
            return {
                gridDataSource: [],
                gridConfig: {},
                order: {},
                items: [],
                images: [],
                gridPopoverEndorsements: {
                    visible: false,
                    target: null,
                    gridConfig: {},
                    items: [],
                    title: null
                },
                gridPopoverFees: {
                    visible: false,
                    target: null,
                    gridConfig: {},
                    items: [],
                    title: null
                },
                gridPopoverImages: {
                    visible: false,
                    target: null,
                    gridConfig: {},
                    items: [],
                    title: null
                },
                warningMsg: null
            }
        },
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary
            }),
            canAdd() { return _.isEqual(this.order?.id, 0); },
            canEdit() { return _.parseNumber(this.order?.id, 0) > 0 && _.includes([EzJacketPolicyStatus.NonRemitted, EzJacketPolicyStatus.Pending], this.order?.orderStatus); },
            canRemit() { return _.isEqual(this.order?.orderStatus, EzJacketPolicyStatus.NonRemitted) && _.every(this.items, { hasImages: true}); },
            canVoid() { return _.isEqual(this.order?.orderStatus, EzJacketPolicyStatus.Remitted) && _.size(this.items) > 0; },
            jacketOrderEffectiveDate() { return _.parseLuxonDateTime(_.trimEnd(this.order?.effectiveDate, 'Z'))?.toFormat("MM/dd/yyyy"); },
            jacketOrderId() { return _.parseNumber(this.order?.id); },
            jacketOrderStatus() { return EzJacketPolicyStatus.displayValue(this.order?.orderStatus); },
            jacketOrderAgencyNumber() { return _.get(this, "order.agency.agencyNumber") || ""; },
            showOrder() { return _.parseNumber(this.order?.id, 0) > 0; },
            showRemit() { return _.isEqual(this.order?.orderStatus, EzJacketPolicyStatus.NonRemitted) && _.size(this.items) > 0; },
            showWarning() { return _.size(this.warningMsg) > 0; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null); },
            policyStatuses() { return EzJacketPolicyStatus.lookupItems; },
            policyCategories() { return EzJacketPolicyCategory.lookupItems; },
            remittanceStatuses(){ return EzJacketRemittanceStatus.lookupItems; },
            selectionActions() {
                return [
                    { name: "schedule-upload", text: "Upload Schedule", eventName: "schedule-upload", requireSelection: true, allowMultiSelection: false, disabled: function(e) { return !_.includes([EzJacketPolicyStatus.NonRemitted, EzJacketPolicyStatus.Pending], e.data.policyStatus); }  },
                ];
            },
        },
        created () {
            this.initGridConfig();
            this.fetchData();
        },
        methods: {
            elementName(prefix = "", suffix = "") {
                return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`);
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "policyNumber",
                            dataType: "string",
                            caption: "Policy #",
                        },
                        {
                            dataField: "name",
                            dataType: "string",
                            caption: "Policy Name",
                        },
                        {
                            dataField: "category",
                            dataType: "number",
                            lookup: {
                                dataSource: self.policyCategories,
                                valueExpr: "id",
                                displayExpr: "name",
                            },
                        },
                        {
                            dataField: "calculatedPremium",
                            dataType: "number",
                            caption: "Premium",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "liability",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            visible: false
                        },
                        {
                            dataField: "priorPremium",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            visible: false
                        },
                        {
                            dataField: "premium",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            visible: false
                        },
                        {
                            dataField: "netPremium",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            visible: false
                        },
                        {
                            dataField: "insuredName",
                            dataType: "string",
                            visible: false
                        },
                        {
                            dataField: "effectiveDate",
                            dataType: "date",
                            caption: "Effective Date",
                            visible: false
                        },
                        {
                            dataField: "policyStatus",
                            dataType: "number",
                            lookup: {
                                dataSource: self.policyStatuses,
                                valueExpr: "id",
                                displayExpr: "name",
                            },
                        },
                        {
                            dataField: "hasImages",
                            dataType: "boolean",
                            caption: "Has Schedules",
                            cellTemplate: DxGridUtils.boolPopoverCellTemplate({
                                idAppend: "policy-image-info-",
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        const items = _.filter(self.images, { policyId: cellInfo.data.id });
                                        self.updateImageGridPopover(items, e.target.id);
                                        e.stopPropagation();
                                    },
                                }
                            }),
                        },
                        {
                            dataField: "hasEndorsements",
                            dataType: "boolean",
                            calculateCellValue: function(data) {
                                return _.size(data.endorsements) > 0;
                            },
                            cellTemplate: DxGridUtils.boolPopoverCellTemplate({
                                idAppend: "policy-endorsement-info-",
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        self.updateEndorsementGridPopover(cellInfo.data.endorsements, e.target.id);
                                        e.stopPropagation();
                                    },
                                }
                            }),
                        },
                        {
                            dataField: "hasFees",
                            dataType: "boolean",
                            calculateCellValue: function(data) {
                                return _.size(data.fees) > 0;
                            },
                            cellTemplate: DxGridUtils.boolPopoverCellTemplate({
                                idAppend: "policy-fee-info-",
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        self.updateFeeGridPopover(cellInfo.data.fees, e.target.id);
                                        e.stopPropagation();
                                    },
                                }
                            }),
                            visible: false
                        },
                        {
                            dataField: "isFinalized",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            visible: false
                        },
                        {
                            dataField: "remittanceStatus",
                            dataType: "number",
                            lookup: {
                                dataSource: self.remittanceStatuses,
                                valueExpr: "id",
                                displayExpr: "name",
                            },
                            visible: false
                        },
                        {
                            dataField: "remittedDateTime",
                            dataType: "datetime",
                            caption: "Remittance Date",
                            visible: false
                        },
                        {
                            dataField: "createdBy",
                            dataType: "string",
                            caption: "Created By",
                        },
                        {
                            dataField: "createdDateTime",
                            dataType: "datetime",
                            caption: "Created On"
                        },
                    ],
                };
                self.gridDataSource = {
                    key: "id",
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },
            fetchData() {
                const self = this;
                let apiPromise = self.$api.SmktEzJacketApi.getPolicyData(self.orderId);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.setData(result);
                    })
                    .catch(error => {
                        console.log(error);
                        self.$toast.error({ message: `Error loading Policy List.` });
                        return error;
                    });
            },
            getPopoverGridColumns(type) {
                if (_.isEqual(type, "Endorsements")) {
                    return {
                        keyExpr: "id",
                        width: "650px",
                        selection: { mode: "none", showCheckBoxesMode: "never" },
                        columns: [
                                {
                                    dataField: "name",
                                    dataType: "string",
                                    minWidth: 400
                                },
                                {
                                    dataField: "calculatedNetPremium",
                                    dataType: "number",
                                    caption: "Net Premium",
                                    format: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    width: 125
                                },
                                {
                                    dataField: "calculatedPremium",
                                    dataType: "number",
                                    caption: "Premium",
                                    format: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    width: 125
                                },
                        ],
                        summary: {
                            totalItems: [
                                {
                                    name: "TotalLabel",
                                    column: "name",
                                    alignment: "left",
                                    displayFormat: "TOTAL",
                                    cssClass: "rq-summary-label",
                                    summaryType: "sum"
                                },
                                {
                                    name: "ItemNetTotal",
                                    column: "calculatedNetPremium",
                                    alignment: "right",
                                    valueFormat: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    displayFormat: "{0}",
                                    summaryType: "sum"
                                },
                                {
                                    name: "ItemTotal",
                                    column: "calculatedPremium",
                                    alignment: "right",
                                    valueFormat: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    displayFormat: "{0}",
                                    summaryType: "sum"
                                },
                            ]
                        }
                    };
                } else if (_.isEqual(type, "Fees")) {
                    return {
                        keyExpr: "id",
                        width: "525px",
                        selection: { mode: "none", showCheckBoxesMode: "never" },
                        columns: [
                                {
                                    dataField: "name",
                                    dataType: "string",
                                    minWidth: 400
                                },
                                {
                                    dataField: "amount",
                                    dataType: "number",
                                    format: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    width: 125
                                },
                        ],
                        summary: {
                            totalItems: [
                                {
                                    name: "TotalLabel",
                                    column: "name",
                                    alignment: "left",
                                    displayFormat: "TOTAL",
                                    cssClass: "rq-summary-label",
                                    summaryType: "sum"
                                },
                                {
                                    name: "ItemTotal",
                                    column: "amount",
                                    alignment: "right",
                                    valueFormat: {
                                        type: "currency",
                                        precision: 2
                                    },
                                    displayFormat: "{0}",
                                    summaryType: "sum"
                                },
                            ]
                        }
                    };
                } else {
                    return {
                        keyExpr: "id",
                        width: "510px",
                        selection: { mode: "none", showCheckBoxesMode: "never" },
                        columns: [
                                {
                                    dataField: "name",
                                    dataType: "string",
                                    minWidth: 400
                                },
                                {
                                    dataField: "status",
                                    dataType: "string",
                                    width: 100
                                },
                        ]
                    };
                }
            },
            onAddOrder() {
                this.$events.$emit("add-jacket", false);
            },
            onEditOrder() {
                this.$events.$emit("edit-jacket", true);
            },
            onRemitOrder(){
                this.showRemitOrderDialog();
            },
            onScheduleUpload(e){
                if(!e || !e.data) return;
                this.showScheduleUploadDialog(e.data);
            },
            onVoidOrder(){
                this.showVoidOrderDialog();
            },
            refresh() {
                if(_.isNull(this.gridInstance)) return;
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },
            remitOrder(paymentMethod) {
                const self = this;
                if (!self.showOrder) return;
                let apiPromise = self.$api.SmktEzJacketApi.remitOrder(self.orderId, self.order.id, paymentMethod);
                return self.$rqBusy.wait(apiPromise);
            },
            showRemitOrderDialog(item) {
                const self = this;
                let onOk = (e) => {
                    const paymentMethod = _.trim(e.component.paymentMethod);
                    return self.remitOrder(paymentMethod)
                            .then(result => {
                                self.setData(result);
                                let orderId = _.parseNumber(result.order.id, null);
                                self.warningMsg = `Order#: ${orderId} has been remitted, please check back later for the status update.`;
                                return true;
                            })
                            .catch(error => {
                                console.log(error.errorMessage);
                                return false;
                            });
                };
                let onCancel = (e) => {
                    self.refresh();
                    return true;
                };
                let title = `Remit Order#: ${self.order.id}`;
                let policyNumbers = _.map(_.filter(self.items, { orderId: self.order.id }), "policyNumber");
                let policyNumberList = _.join(policyNumbers, ", ");
                let noun = _.size(policyNumbers) == 1 ? 'policy' : 'policies';
                let policyMsg = `You are about to remit ${noun} <b>${policyNumberList}</b>, please select a payment method.`;

                self.$dialog.open({
                    title: title,
                    width: "400",
                    height: "300",
                    closeOnEsc: true,
                    component: SmktEzJacketRemitDialog,
                    props: {
                        policyMsg: policyMsg,
                        paymentMethods: EzJacketPaymentMethods.lookupItems
                    },
                    onOk: onOk,
                    onCancel: onCancel
                });
            },
            showScheduleUploadDialog(item) {
                const self = this;
                let onOk = (e) => {
                    return e.component.save()
                            .then(result => {
                                self.fetchData();
                                return true;
                            })
                            .catch(error => {
                                e.component.errorMessage = error.errorMessage;
                                console.log(error.errorMessage);
                                return false;
                            });
                };
                let onCancel = (e) => {
                    return true;
                };
                let title = `Upload Documents to Policy#: ${item.policyNumber.trim()}`;
                self.$dialog.open({
                    title: title,
                    width: "60%",
                    height: "60%",
                    closeOnEsc: true,
                    component: SmktEzJacketScheduleUploadDialog,
                    props: { id: self.order.id, policyId: item.id},
                    onOk: onOk,
                    onCancel: onCancel
                });
            },
            showVoidOrderDialog() {
                const self = this;
                let onOk = (e) => {
                    const voidReason = _.trim(e.component.voidReason);
                    return self.voidOrder(voidReason)
                            .then(result => {
                                self.setData(result);
                                return true;
                            })
                            .catch(error => {
                                console.log(error.errorMessage);
                                return false;
                            });
                };
                let onCancel = (e) => {
                    self.refresh();
                    return true;
                };
                let title = `Void Order#: ${self.order.id}`;
                let policyNumbers = _.map(_.filter(self.items, { orderId: self.order.id }), "policyNumber");
                let policyNumberList = _.join(policyNumbers, ", ");
                let noun = _.size(policyNumbers) == 1 ? 'policy' : 'policies';
                let policyMsg = `You are about to void ${noun} <b>${policyNumberList}</b>, are you sure you want to do this?`;

                self.$dialog.open({
                    title: title,
                    width: "400",
                    height: "300",
                    closeOnEsc: true,
                    component: SmktEzJacketVoidDialog,
                    props: { policyMsg: policyMsg},
                    onOk: onOk,
                    onCancel: onCancel
                });
            },
            setData(data) {
                const self = this;
                self.order = data.order || { id: 0, orderStatus: 0 };
                self.items = data.order?.policies || [];
                self.images = data.images;
                self.refresh();
            },
            updateEndorsementGridPopover(items=null, target=null) {
                const self = this;
                let popoverGridConfig = self.getPopoverGridColumns("Endorsements");
                let newID = target;
                let lastID = _.get(self.gridPopoverEndorsements, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let gridPopover = _.clone(self.gridPopoverEndorsements);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        gridPopover.visible = false;
                    }
                    gridPopover.target = newID;
                    gridPopover.gridConfig = popoverGridConfig;
                    gridPopover.items = items;
                    gridPopover.title = "Endorsements";
                    gridPopover.visible = true;
                } else {
                    gridPopover.visible = !gridPopover.visible;
                }
                self.$nextTick(() => {
                    self.gridPopoverEndorsements = gridPopover;
                });
            },
            updateFeeGridPopover(items=null, target=null) {
                const self = this;
                let popoverGridConfig = self.getPopoverGridColumns("Fees");
                let newID = target;
                let lastID = _.get(self.gridPopoverFees, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let gridPopover = _.clone(self.gridPopoverFees);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        gridPopover.visible = false;
                    }
                    gridPopover.target = newID;
                    gridPopover.gridConfig = popoverGridConfig;
                    gridPopover.items = items;
                    gridPopover.title = "Fees";
                    gridPopover.visible = true;
                } else {
                    gridPopover.visible = !gridPopover.visible;
                }
                self.$nextTick(() => {
                    self.gridPopoverFees = gridPopover;
                });
            },
            updateImageGridPopover(items=null, target=null) {
                const self = this;
                let popoverGridConfig = self.getPopoverGridColumns("Images");
                let newID = target;
                let lastID = _.get(self.gridPopoverImages, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let gridPopover = _.clone(self.gridPopoverImages);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        gridPopover.visible = false;
                    }
                    gridPopover.target = newID;
                    gridPopover.gridConfig = popoverGridConfig;
                    gridPopover.items = items;
                    gridPopover.title = "Schedules";
                    gridPopover.visible = true;
                } else {
                    gridPopover.visible = !gridPopover.visible;
                }
                self.$nextTick(() => {
                    self.gridPopoverImages = gridPopover;
                });
            },
            voidOrder(voidReason) {
                const self = this;
                if (!self.showOrder) return;
                let apiPromise = self.$api.SmktEzJacketApi.voidOrder(self.orderId, self.order.id, voidReason);
                return self.$rqBusy.wait(apiPromise);
            },
        }
    }
</script>