<template>
    <div class="row">
        <div class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group">
            <rq-page-section
                title="Signing Details"
                section-group="closing-group"
                collapsible
                borderless
            >
                <div class="row">
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 form-group">
                        <label for="txt_description">Description</label>
                        <input
                            id="txt_description"
                            automation_id="txt_description"
                            type="text"
                            class="form-control"
                            placeholder="Description"
                            v-model="localItem.description"
                            :readonly="readOnly || isComplete"
                            maxlength="250"
                        />
                    </div>
                    <!--signing type type-->
                    <div
                        class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group"
                    >
                        <label for="drp_closing_type">Signing Type</label>
                        <dx-select-box
                            :input-attr="{
                                id: 'drp_signing_type',
                                automation_id: 'drp_signing_type',
                            }"
                            :items="signingTypes"
                            value-expr="id"
                            display-expr="name"
                            v-model="localItem.signingTypeID"
                            :search-enabled="true"
                            :disabled="readOnly || isComplete"
                            @valueChanged="signingChanged"
                        />
                    </div>
                    <!--signing status-->
                    <div
                        class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group"
                    >
                        <label for="drp_signing_status">Signing Status</label>
                        <dx-select-box
                            :input-attr="{
                                id: 'drp_signing_status',
                                automation_id: 'drp_signing_status',
                            }"
                            :items="getFilteredSigningStatuses()"
                            value-expr="id"
                            display-expr="name"
                            v-model="localItem.signingStatusID"
                            :search-enabled="true"
                            :disabled="readOnly || isComplete"
                        />
                    </div>
                </div>
                <div class="row">
                    <!--close date-->
                    <div
                        class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group" :class="{ 'has-error':v$.localItem.signingDate.uniqueDate.$invalid}"
                        >
                        <label for="dtp_close_date">Signing Date</label>
                        <rqdx-date-box
                            id="dtp_close_date"
                            type="datetime"
                            v-model="v$.localItem.signingDate.$model"
                            @valueChanged="onSigningDateChanged"
                            :disabled="readOnly || isComplete"
                        />
                        <rq-validation-feedback>Multiple electronic signings cannot be conducted on the same file at the same time.</rq-validation-feedback>
                    </div>
                    <div class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                        <label for="drp_duration">Duration</label>
                        <dx-select-box
                            :input-attr="{
                                id: 'drp_duration',
                                automation_id: 'drp_duration',
                            }"
                            :items="durations"
                            value-expr="id"
                            display-expr="name"
                            v-model="localItem.duration"
                            :disabled="readOnly || isComplete"
                        />
                    </div>
                </div>
            </rq-page-section>
            <rq-page-section
                title="Location"
                section-group="closing-group"
                collapsible
                borderless
            >
                <template #header-actions>
                    <div>
                        <b-btn
                            variant="link"
                            class="d-inline-block"
                            @click="fillClosingAddress">
                            Place of Closing
                        </b-btn>
                        <b-btn
                            variant="link"
                            class="d-inline-block"
                            @click="fillPropertyAddress">
                            Property Address
                        </b-btn>
                        <b-btn
                            v-if="localItem.signingDate"
                            v-rq-tooltip.hover.top="{
                                title: 'Download to Calendar'
                            }"
                            variant="icon"
                            class="d-inline-block"
                            @click="downloadIcs">
                            <FontAwesomeIcon icon="fas fa-calendar-alt" />
                        </b-btn>
                    </div>
                </template>

                <div class="row">
                    <!--address 1-->
                    <div
                        class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group"
                    >
                        <label for="txt_address_1">Address 1</label>
                        <dx-autocomplete
                            ref="addressSigningAutocomplete"
                            class="form-control"
                            :dataSource="addressDataSource"
                            item-template="item-template"
                            :min-search-length="3"
                            :input-attr="addressInputAttr"
                            :disabled="isComplete"
                            value-expr="text"
                            v-model="localItem.address1"
                            @initialized="onAddressContentReady"
                            @focus-out="onAddress1FocusOut"
                            @focus-in="onAddress1FocusIn"
                            @opened="onAddress1Opened"
                            @selectionChanged="onAutoCompleteSelectionChanged"
                            @value-changed="onAutoCompleteValueChanged"
                        >
                            <template #item-template="{ data }">
                                <div>{{data ? data.text : ""}}</div>
                            </template>
                        </dx-autocomplete>
                    </div>
                    <!--address 2-->
                    <div class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                        <label for="txt_address_2">Address 2</label>
                        <input
                            id="txt_address_2"
                            automation_id="txt_address_2"
                            type="text"
                            class="form-control"
                            placeholder="Address 2"
                            v-model="localItem.address2"
                            :readonly="readOnly || isComplete"
                        />
                    </div>
                </div>
                <div class="row">
                    <!--city-->
                    <div
                        class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group"
                    >
                        <label for="txt_city">City</label>
                        <input
                            id="txt_city"
                            automation_id="txt_city"
                            type="text"
                            class="form-control"
                            placeholder="City"
                            v-model="localItem.city"
                            :readonly="readOnly || isComplete"
                        />
                    </div>
                    <!--state/zip-->
                    <div
                        class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group"
                    >
                        <div class="row">
                            <div
                                class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group"
                            >
                                <label for="drp_state">State</label>
                                <dx-select-box
                                    :input-attr="{
                                        automation_id: 'drp_state',
                                        id: 'drp_state',
                                    }"
                                    :items="usStates"
                                    value-expr="id"
                                    display-expr="id"
                                    :show-clear-button="true"
                                    v-model="localItem.state"
                                    :disabled="readOnly || isComplete"
                                />
                            </div>
                            <div
                                class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group"
                            >
                                <label for="txt_zip">Zip</label>
                                <input
                                    id="txt_zip"
                                    automation_id="txt_zip"
                                    type="text"
                                    class="form-control"
                                    placeholder="Zip"
                                    v-model="localItem.zip"
                                    :readonly="readOnly || isComplete"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </rq-page-section>
        </div>
        <div class="col col-6 col-sm-6 col-md-6 col-lg-6 form-group">
            <div class="col">
                <rqdx-action-data-grid
                    ref="actionGridComponent"
                    title="Attendees"
                    class="task-grid"
                    automation_id="dg_attendees"
                    :data-source="gridDataSource"
                    :config="gridConfig"
                    integrated-search
                    rq-filters
                    @delete="onDeleteAttendees"
                    :actions="selectionActions"
                    :readonly="readOnly || isComplete"
                >
                    <template #toolbar>
                        <ul class="nav">
                            <li class="nav-item">
                                <b-btn
                                    automation_id="btn_assign_contact"
                                    variant="theme"
                                    @click="showFileContactsDialog"
                                    :disabled="readOnly || isComplete"
                                    >Assign Contact</b-btn
                                >
                            </li>
                        </ul>
                    </template>
                </rqdx-action-data-grid>
            </div>
            <div class="col">
                <rqdx-action-data-grid
                    ref="documentActionGridComponent"
                    title="Documents"
                    class="task-grid"
                    automation_id="dg_documents"
                    :data-source="documentsGridDataSource"
                    :config="documentsGridConfig"
                    @delete="onDeleteDocuments"
                    :actions="documentSelectionActions"
                    :readonly="readOnly || isComplete"
                    hide-default-actions
                    hide-search
                >
                    <template #toolbar>
                        <ul class="nav">
                            <li class="nav-item">
                                <b-btn
                                    automation_id="btn_assign_contact"
                                    variant="theme"
                                    @click="showFileScanDocumentsDialog"
                                    :disabled="readOnly || isComplete"
                                    >Assign Document</b-btn
                                >
                            </li>
                        </ul>
                    </template>
                </rqdx-action-data-grid>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import { mapState, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { ORDER_ACTIONS, PROPERTY_ACTIONS } from "@/store/actions";
import FileContactSelection from "@file-shared/components/FileContactSelection";
import FileScanDocumentList from "@documents/components/selection/FileScanDocumentList";
import { OrderSigningRole, OrderSigningFileScanDocument } from "../models";
import { DateTime, Duration } from "luxon";
import { DocumentFileType } from '@/modules/documents/enums';
import { useLicenseStore } from "@/store/modules/license";
import { AddressHelper } from '@/shared/utilities';
import { SigningDocumentStatus } from "../enum";

export default {
    name: "SigningForm",
    props: {
        item: { type: Object, default: () => ({}) },
        fileScanDocuments: { type: Array, default: () => []},
        isUniqueSigningDate: { type: Function, default: (() => true) },
        isComplete: { type: Boolean, default: false }
    },
    data() {
        return {
            selectionActions: [],
            documentSelectionActions: [],
            addressDataSource: {},
            addressInputAttr: {},
            addressContentReady: false,
            isAddress1Focused: false,
        };
    },
    setup(props, { emit }) {

        const v$ = useVuelidate();
        const licenseStore = useLicenseStore();

        const pavasoEnabled = computed(() => licenseStore.features?.pavasoDigitalSignings);

        return {
            v$,
            pavasoEnabled
        };
    },
    created() {
        const self = this;
        self.init();
        self.initAttendeesGridConfig();
        self.initDocumentsGridConfig();
        self.initAddressAutocomplete();
    },
    validations() {
        const self = this;
        return {
            localItem: {
                signingDate: {
                    uniqueDate: (value, item) => self.isUniqueSigningDate(item.orderSigningID, value, item.signingTypeID)
                }
            }
        };
    },
    emits: ["update:item", "open-tagging-session"],
    computed: {
        localItem: {
            get() {
                return this.item;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        ...mapState({
            readOnly: state => state.isPageReadOnly,
            orderState: state => state.orders.order,
            properties: state => state.properties.properties,
        }),
        ...mapGetters(["lookupHelpers", "lookupItems"]),
        gridInstance() {
            return this.$refs.actionGridComponent.getGridInstance();
        },
        documentGridInstance(){
            return this.$refs.documentActionGridComponent.getGridInstance();
        },
        ordersID() {
            return _.getNumber(this, "$route.params.orderId", 0);
        },
        signingTypes() {
            return _.filter(
                this.lookupHelpers.getAllLookupItems(
                    this.lookupItems.CLOSING_TYPES
                ),
                (item) => item.inactive != true
            );
        },
        signingStatuses() {
            return _.filter(
                this.lookupHelpers.getAllLookupItems(
                    this.lookupItems.SIGNING_STATUSES
                ),
                (item) => item.inactive != true
            );
        },
        usStates() {
            return this.lookupHelpers.getStates();
        },
        durations() {
            return this.lookupHelpers.getLookupItems(
                this.lookupItems.DURATIONS
            );
        },
        allCounties() { return this.lookupHelpers.getLookupItems('counties'); },
        assignedFileScanDocumentIDs() { return _.map(this.item.orderSigningFileScanDocuments, d => d.fileScanDocumentID); },
        availableDocuments() { return _.filter(this.fileScanDocuments, d => !_.includes(this.assignedFileScanDocumentIDs, d.fileScanDocumentID) && (d.fileType === DocumentFileType.PDF || d.fileType === DocumentFileType.PDF_COMBINED));}
    },
    watch:{
        "orderState.pavasoOrderGuid":{
            handler: function(newValue, oldValue) {
                this.documentGridInstance.refresh();
            }
        }
    },
    methods: {
        init() {
            const self = this;
            self.$store.dispatch(ORDER_ACTIONS.GET_ORDER, { orderId: self.ordersID });
            self.$store.dispatch(PROPERTY_ACTIONS.GET_PROPERTIES, false);
            self.selectionActions = [
                {
                    name: "delete",
                    text: "Delete",
                    eventName: "delete",
                    allowMultiSelection: true,
                    tooltip: `Delete Attendee(s)`,
                    disabled: function (e) {
                        if (self.readOnly || self.isComplete) {
                            return true;
                        }
                        return false;
                    },
                },
            ];

            self.documentSelectionActions = [
                {
                    name: "delete",
                    text: "Delete",
                    eventName: "delete",
                    allowMultiSelection: true,
                    tooltip: `Delete Document(s)`,
                    disabled: function (e) {
                        if (self.readOnly || self.isComplete) {
                            return true;
                        }
                        return false;
                    },
                },
            ];
        },
        getFilteredSigningStatuses() {
            const self = this;
            if (self.item.signingTypeID == _.find(self.signingTypes, y => y.name === 'Paper').id || self.isComplete) {
                return self.signingStatuses;
            } else {
                return _.filter(self.signingStatuses, x => x.name != 'Complete')
            }
        },
        initAddressAutocomplete() {
            const self = this;
            self.addressDataSource = {
                key: "text",
                load(loadOptions){
                    let propertyState = !_.isEmpty(self.properties) ? self.properties[0].property.state : "";
                    return self.$api.UtilitiesApi.addressAutoComplete(loadOptions.searchValue, self.localItem.state || propertyState);
                }
            };
            self.addressInputAttr = {
                autocomplete: Math.random(),
                automation_id: "txt_property_address1",
            };
        },
        onAddressContentReady(e) {
            const self = this;
            if (self.addressContentReady) return;
            self.addressContentReady = true;
            self.$nextTick(() => {
                e.component.focus();
            });
        },
        onAddress1FocusIn(e){
            this.isAddress1Focused = true;
        },

        onAddress1FocusOut(e){
            this.isAddress1Focused = false;
        },
        onAddress1Opened(e){
            if(this.isAddress1Focused || !this.$refs.addressSigningAutocomplete) return;
            this.setAddressAutocompleteOption("opened", false);
        },

        onAutoCompleteSelectionChanged(e) {
            const self = this;

            let selectedItem = e.component.option("selectedItem");

            if(!_.isObject(selectedItem)) return;

            self.updateAddress({
                address1: selectedItem.address1,
                address2: selectedItem.address2,
                city: selectedItem.city,
                state: selectedItem.state,
                zip: selectedItem.zip
            }, false);

            // Then call subsequent validation.
            self.validateAddress();
        },
        onSigningDateChanged(e) {
            const self = this;
            if(_.isNil(e?.event) || !_.isDateOnWeekend(e?.value)) return;

            const ok = () => self.localItem.signingDate = e.value;
            const cancel = () => self.localItem.signingDate = e.previousValue;

            self.$dialog.confirm(
                "Confirm",
                "Are you sure you want to schedule a signing on a weekend?",
                ok,
                cancel,
                { cancelTitle: 'No', okTitle: 'Yes'}
            );
        },
        validateAddress(property={}, showToast=true) {
            const self =  this;

            let entity = !_.isEmpty(property) ? property : self.mapAddressForValidation();

            self.isProcessingAddress = true;

            return self.$api.UtilitiesApi
                .verifyAddress(entity)
                .then((response) => {

                    if(!response.isVerified) {
                        self.localItem.isValidatedAddress = false;
                        if(showToast)
                        {
                            self.$toast.error("Invalid Address");
                        }
                        return false;
                    }

                    self.updateAddress(response, true);
                    return true;
                })
                .catch((error) => {
                    self.localItem.isValidatedAddress = false;
                    if(showToast)
                    {
                        self.$toast.error("Invalid Address");
                    }
                    return false;
                })
                .finally(() => {
                    self.isProcessingAddress = false;
                })
        },
        onAutoCompleteValueChanged(e) {
            if(_.isNil(e.event)) return;
            const self = this;

            self.resetValidatedAddress();
        },
        resetValidatedAddress(e) {
            const self = this;

            self.localItem.isValidatedAddress = false;
        },
        updateAddress(property, isAddressValidated) {
            const self = this;

            self.localItem.address1 = property.address1;
            self.localItem.address2 = property.address2;
            self.localItem.city = property.city;
            self.localItem.state = property.state;
            self.localItem.zip = property.zip;
            self.localItem.countyID = property.countyId;
            self.localItem.countyName = property.county;

            self.updateCounty();

            self.localItem.isValidatedAddress = isAddressValidated;
        },
        updateCounty() {
            let self = this;
            let county = null;
            if (!_.isNil(self.localItem.countyID)) {
                county = _.find(self.allCounties, { id: self.localItem.countyID });
            }
            else if (!_.isNil(self.localItem.countyName)) {
                county = _.find(self.allCounties, (county) => 
                        AddressHelper.isCountyNameSame(county.name, self.localItem.countyName) &&
                        county.data.toLowerCase().trim() === self.localItem.state.toLowerCase().trim()
                    );
            }

            if (!county) {
                self.localItem.countyName = "";
            }
            else {
                self.localItem.countyName = county.name;
                self.localItem.countyID = county.id;
            }
        },
        onValidateAddress() {
            const self = this;

            self.$rqBusy.wait(self.validateAddress(), { targetElement: _.get(self, "$refs.imgContainerElement", null) });
        },
        mapAddressForValidation() {
            const self = this;

            return {
                address1: self.localItem.address1,
                address2: self.localItem.address2,
                city: self.localItem.city,
                state: self.localItem.state,
                zip: self.localItem.zip
            }
        },
        touch() {
            this.v$.$touch();
            return this.v$.$error;
        },
        initAttendeesGridConfig() {
            const self = this;
            let columns = [
                {
                    dataField: "role",
                    dataType: "string",
                    caption: "Role",
                    visible: true,
                },
                {
                    dataField: "contact",
                    dataType: "string",
                    caption: "Contact",
                    visible: true,
                },
                {
                    dataField: "phone",
                    dataType: "string",
                    caption: "Phone",
                    visible: true,
                },
                {
                    dataField: "email",
                    dataType: "string",
                    caption: "Email",
                    visible: true,
                },
                {
                    dataField: "company",
                    dataType: "string",
                    caption: "Company",
                    visible: false,
                },
            ];

            self.gridConfig = {
                columns,
                height: "200px",
            };

            self.gridDataSource = {
                key: "rolesID",
                loadMode: "raw",
                load() {
                    return Promise.resolve(self.localItem.orderSigningRoles);
                },
            };
        },

        initDocumentsGridConfig() {
            const self = this;
            let columns = [
                {
                    dataField: "description",
                    dataType: "string",
                    visible: true,
                    cellTemplate: function(cellElement, cellInfo) {
                        if(self.pavasoEnabled && !_.isNil(self.orderState.pavasoOrderGuid) && self.localItem.signingTypeID !== 2 && cellInfo.row?.data?.orderSigningFileScanDocumentsID > 0){
                            let link = document.createElement('a');
                            link.href = '#';
                            link.innerText = cellInfo.value;
                            link.onclick = function(event) {
                                event.preventDefault();
                                self.$emit('open-tagging-session', cellInfo.data);
                            };
                            cellElement.append(link);
                        }
                        else{
                             cellElement.append(cellInfo.value);
                        }
                    },
                },
                {
                    dataField: "status",
                    caption: "Document Status",
                    dataType: "string",
                    cellTemplate: function(cellElement, cellInfo) {
                        let status = SigningDocumentStatus.displayValue(cellInfo.data.orderSigningDocumentStatusID);
                        cellElement.append(status);
                    }
                }
            ];

            self.documentsGridConfig = {
                columns,
                height: "200px",
            };

            self.documentsGridDataSource = {
                key: "fileScanDocumentID",
                loadMode: "raw",
                load() {
                    return Promise.resolve(self.localItem.orderSigningFileScanDocuments);
                },
            };
        },

        showFileContactsDialog() {
            const self = this;
            let onOk = (e) => {
                let contact = e.component.selectedContact;

                let existingRole = _.find(
                    self.localItem.orderSigningRoles,
                    (r) => r.rolesID == contact.rolesID
                );

                if (_.isNil(existingRole)) {
                    let options = {};
                    options.orderSigningID = self.localItem.orderSigningID;
                    options.rolesID = contact.rolesID;
                    options.role = contact.role;
                    options.contact = contact.name;
                    options.email = contact.email;
                    options.phone = contact.phone;
                    let orderSigningRole = new OrderSigningRole(options);
                    self.localItem.orderSigningRoles.push(orderSigningRole);
                    this.gridInstance.refresh();
                }
            };
            self.$dialog.open({
                title: "Select File Contact",
                height: "700",
                width: "1000",
                resizable: true,
                adaptive: false,
                closeOnEsc: true,
                component: FileContactSelection,
                onOk: onOk,
                props: {
                    ordersID: self.ordersID,
                    excludeCompanies: true,
                },
            });
        },

        showFileScanDocumentsDialog() {
            const self = this;
            let onOk = (e) => {
                let items = e.component.getSelectedItems();
                _.forEach(items, item => {
                    let options = {};
                    options.orderSigningID = self.localItem.orderSigningID;
                    options.fileScanDocumentID = item.fileScanDocumentID;
                    options.description = item.description;
                    self.localItem.orderSigningFileScanDocuments.push(new OrderSigningFileScanDocument(options));
                    self.documentGridInstance.refresh();
                });

            };

            let availableDocuments = _.cloneDeep(self.availableDocuments);

            self.$dialog.open({
                title: "Select Available Documents",
                height: "700",
                width: "1000",
                resizable: true,
                adaptive: false,
                closeOnEsc: true,
                component: FileScanDocumentList,
                onOk: onOk,
                props: {
                    title: "Available Documents",
                    items: availableDocuments,
                    instructions: "Select a document or documents below and click \"Ok\" to assign them to the current Signing.",
                    excludeSelectionActions: true
                },
            });
        },
        onDeleteAttendees(e) {
            const self = this;
            if (!e || !e.data) return;
            let items = e.data;
            let okHandler = function (args) {
                _.forEach(items, (i) => {
                    let index = _.findIndex(
                        self.localItem.orderSigningRoles,
                        (r) => {
                            return r.rolesID === i.rolesID;
                        }
                    );
                    let removedRoles = self.localItem.orderSigningRoles.splice(
                        index,
                        1
                    );
                    if (removedRoles[0].orderSigningRolesID > 0)
                        self.localItem.deleteOrderSigningRoles.push(i);
                });

                self.gridInstance.refresh();
                return true;
            };

            self.$dialog.confirm(
                "Confirm Delete",
                "Are you sure you want to delete the selected attendees?",
                okHandler,
                null,
                { cancelTitle: "No", okTitle: "Yes" }
            );
        },
        onDeleteDocuments(e){
            const self = this;
            if (!e || !e.data) return;
            let items = e.data;
            let okHandler = function (args) {
                _.forEach(items, (i) => {
                    let index = _.findIndex(
                        self.localItem.orderSigningFileScanDocuments,
                        (r) => {
                            return r.fileScanDocumentID === i.fileScanDocumentID;
                        }
                    );
                    let removedDocuments = self.localItem.orderSigningFileScanDocuments.splice(
                        index,
                        1
                    );
                    if (removedDocuments[0]?.orderSigningFileScanDocumentsID > 0)
                        self.localItem.deleteOrderSigningFileScanDocuments.push(i);
                });

                self.documentGridInstance.refresh();
                return true;
            };

            self.$dialog.confirm(
                "Confirm Delete",
                "Are you sure you want to delete the selected documents?",
                okHandler,
                null,
                { cancelTitle: "No", okTitle: "Yes" }
            );
        },
        fillClosingAddress() {
            const self = this;
            const companyId = self.orderState.placeOfClosingCompanyId;
            if (!companyId) {
                self.$toast.info("Place of Closing has not been set.");
                return;
            }
            self.$rqBusy
                .wait(self.$api.CompaniesApi.getCompany(companyId))
                .then(company => {
                    if (!company) {
                        self.$toast.info("Place of Closing could not be found.");
                        return;
                    }
                    self.localItem.address1 = company.address1;
                    self.localItem.address2 = company.address2;
                    self.localItem.city = company.city;
                    self.localItem.state = company.state;
                    self.localItem.zip = company.zip;
                })
                .catch(error => {
                    self.$toast.error(`Error getting place of closing. ${error.errorMessage}`);
                });
        },
        fillPropertyAddress() {
            const self = this;
            self.$rqBusy
                .wait(self.$api.PropertiesApi.getProperties(self.ordersID))
                .then(([{property}]) => {
                    if (!property) {
                        self.$toast.info("Property Address could not be found.");
                        return;
                    }
                    self.localItem.address1 = property.address1;
                    self.localItem.address2 = property.address2;
                    self.localItem.city = property.city;
                    self.localItem.state = property.state;
                    self.localItem.zip = property.zip;
                })
                .catch(error => {
                    self.$toast.error(`Error getting property address. ${error.errorMessage}`);
                });
        },
        downloadIcs() {
            const content = this.icsContent()
            const blob = new Blob([content], { type: "text/calendar;charset=utf-8" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "signing.ics";
            a.click();
            URL.revokeObjectURL(url);
        },
        signingChanged(e){
            if(_.isNil(e?.event)) return;
            this.documentGridInstance.refresh();
        },
        icsContent() {
            const self = this;
            let address = `${self.localItem.address1 ?? ""}, ${self.localItem.address2 ? (self.localItem.address2 + ', ') : ''}`
            + `${self.localItem.city ?? ""}, ${self.localItem.state ?? ""} ${self.localItem.zip ?? ""}`;

            address = address.trim();
            if (address.startsWith(',') || address.endsWith(',')) address = "";

            const durations = [
                Duration.fromObject({ minutes: 30 }),
                Duration.fromObject({ hours: 1 }),
                Duration.fromObject({ hours: 1, minutes: 30 }),
                Duration.fromObject({ hours: 2 }),
                Duration.fromObject({ hours: 2, minutes: 30 }),
                Duration.fromObject({ hours: 3 }),
            ];

            const zone = DateTime.local().zoneName
            const start = DateTime.fromISO(self.localItem.signingDate).setZone(zone);
            const end = start.plus(durations[self.localItem.duration] ?? Duration.fromObject({ minutes: 30 }));

            return `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:Calendar
BEGIN:VEVENT
UID:${self.localItem.description ?? ""}
SUMMARY:${self.localItem.description ?? ""}
STATUS:CONFIRMED
DTSTART:${start.toUTC().toFormat("yyyyMMdd'T'HHmmss'Z'")}
DTEND:${end.toUTC().toFormat("yyyyMMdd'T'HHmmss'Z'")}
DTSTAMP:${DateTime.utc().toFormat("yyyyMMdd'T'HHmmss'Z'")}
LOCATION:${address ?? ""}
DESCRIPTION:${self.localItem.description ?? ""}
 \\n${address ?? ""}
END:VEVENT
END:VCALENDAR`.trimStart();

        }
    },
};
</script>
