
<template>
    <div :class="{ 'content-wrapper realtor-commission':true, 'split-commission':splitCommissions }">
        <rq-banner
            variant="warning"
            :message="firstLoanMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showFirstLoanMessage"
        />
        <rq-banner
            variant="warning"
            :message="buyerSellerMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showBuyerSellerMessage"
            dismissable
        />
        <rq-banner
            variant="error"
            title="Commission Split does not equal Total Commission amount."
            icon="fas fa-exclamation-triangle"
            :visible="listingAgentCommissionSplitUnbalanced || sellerAgentCommissionSplitUnbalanced"
        />
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="(buyersAgentCommissionInvalid || splitPercentInvalid) && showAgentCommissionInvalidBanner"
        />
        <rq-page-section title="COMMISSIONS" header-size="lg" borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <rq-report-button
                            text="Print Report"
                            :disabled="readOnly"
                            :path="reportOptions.path"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
            </template>
            <fieldset>
                <div class="row">
                    <div class="col col-12 col-lg-3">
                        <div class="row">
                            <rq-action-form-group
                                label="Sales Price"
                                action-automation-id="btn_revert_sales_price"
                                action-label="Revert"
                                action-class="overridden"
                                :show-action="commission.salesPriceOverridden && !readOnly"
                                class="col col-sm-12 col-lg"
                                @action-click="onRevertSalesPrice(false)">
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <rq-input-number
                                        id="txt_sales_price_override"
                                        ref="salesPriceInput"
                                        defaultValue="0"
                                        decimals="2"
                                        v-model="commission.salesPriceOverride"
                                        prefix="$"
                                        @change="onSalesPriceChange"
                                        v-focus
                                        :disabled="readOnly"
                                    />
                                </div>
                            </rq-action-form-group>
                            <div class="col-auto col-opr px-0">
                                <span class="rq-content-description">@</span>
                            </div>
                        </div>
                    </div>
                    <div class="col col-12 col-lg-3">
                        <div class="row">
                            <div class="col form-group">
                                <label class="form-control-label" for="txt_total_commission_percent">Percent</label>
                                <div class="input-group">
                                    <rq-input-number id="txt_total_commission_percent"
                                        automation_id="txt_total_commission_percent"
                                        suffix="%"
                                        defaultValue="0"
                                        minValue="0"
                                        maxValue="100"
                                        decimals="2"
                                        value-event="input"
                                        v-focus-select-all
                                        v-model="commission.totalPercent"
                                        @change="onTotalPercentChange"
                                        :disabled="readOnly"
                                    />
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="splitCommissions" class="col col-12 col-lg-3">
                        <div class="row">
                            <div class="col-auto col-opr px-0">
                                <span class="rq-content-description">/</span>
                            </div>
                            <div class="col form-group">
                                <label class="form-control-label" for="txt_total_commission_split_percent">Split Percent</label>
                                <div class="input-group">
                                    <rq-input-number
                                        id="txt_total_commission_split_percent"
                                        automation_id="txt_total_commission_split_percent"
                                        suffix="%"
                                        defaultValue="0"
                                        minValue="0"
                                        maxValue="100"
                                        decimals="2"
                                        value-event="input"
                                        v-focus-select-all
                                        v-model="commission.totalSplitPercent"
                                        @change="onTotalSplitPercentChange"
                                        :disabled="readOnly"
                                    />
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-12 col-lg-3">
                        <div class="row">
                            <div class="col-auto col-opr px-0">
                                <span class="rq-content-description">=</span>
                            </div>
                            <rq-action-form-group
                                label="Total Commission"
                                action-automation-id="btn_revert_total_commission"
                                action-label="Revert"
                                action-class="overridden"
                                :show-action="commission.totalAmountOverridden && !readOnly"
                                class="col col-sm-12 col-lg"
                                @action-click="onRevertCommissionAmount(false)">
                                    <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <rq-input-number
                                        id="txt_total_commission_amount"
                                        defaultValue="0"
                                        decimals="2"
                                        value-event="input"
                                        v-model="commission.totalAmountVal"
                                        prefix="$"
                                        @change="onCommissionTotalAmountInput"
                                        :disabled="readOnly"
                                    />
                                    </div>
                            </rq-action-form-group>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-lg-3">
                        <div class="row">
                            <rq-action-form-group
                                v-if="splitCommissions"
                                label="Split At Amount"
                                action-automation-id="btn_revert_split_amt"
                                action-label="Revert"
                                action-class="overridden"
                                :show-action="commission.splitAtAmountOverridden && !readOnly"
                                class="col col-sm-12 col-lg"
                                @action-click="onRevertSplitAtAmount">
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <rq-input-number id="txt_split_override"
                                        automation_id="txt_split_override"
                                        formatType="money"
                                        defaultValue="0"
                                        :minValue="0"
                                        decimals="2"
                                        value-event="input"
                                        v-focus-select-all
                                        v-model="commission.splitAtAmountOverride"
                                        @change="onSplitAtAmountChange"
                                        :disabled="readOnly"
                                    />
                                </div>
                            </rq-action-form-group>
                        </div>
                    </div>
                    <div class="col col-12 col-lg-3">
                        <div class="row">
                            <rq-action-form-group
                                :label="secondaryPercentLabel"
                                class="col col-sm-12 col-lg form-group" :class="{'has-error' : buyersAgentCommissionInvalid }">
                                <div class="input-group">
                                    <rq-input-number
                                        id="txt_agent2_percent"
                                        defaultValue="0"
                                        minValue="0"
                                        maxValue="100"
                                        decimals="2"
                                        value-event="input"
                                        v-focus-select-all
                                        v-model="commission.sellingAgentPayee.commissionPercent"
                                        suffix="%"
                                        @change="onSecondaryPercentChange"
                                        :disabled="readOnly"
                                    />
                                    <span class="input-group-text">%</span>
                                </div>
                                <rq-validation-feedback>Amount cannot be greater than the Percent</rq-validation-feedback>
                            </rq-action-form-group>
                        </div>
                    </div>
                    <div v-if="splitCommissions" class="col col-12 col-lg-3">
                        <div class="row">
                            <div class="col-auto col-opr px-0">
                                <span class="rq-content-description">/</span>
                            </div>
                            <rq-action-form-group
                                label="Split Percent"
                                class="col form-group" :class="{'has-error' : splitPercentInvalid }">
                                <div class="input-group">
                                    <rq-input-number
                                        id="txt_agent2_split_percent"
                                        automation_id="txt_agent2_split_percent"
                                        suffix="%"
                                        defaultValue="0"
                                        minValue="0"
                                        maxValue="100"
                                        decimals="2"
                                        value-event="input"
                                        v-focus-select-all
                                        v-model="commission.sellingAgentPayee.commissionSplitPercent"
                                        @change="onSecondarySplitPercentChange"
                                        :disabled="readOnly"
                                    />
                                    <span class="input-group-text">%</span>
                                </div>
                                <rq-validation-feedback>Amount cannot be greater than the Split Percent</rq-validation-feedback>
                            </rq-action-form-group>
                        </div>
                    </div>
                    <div class="col col-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_acct_code">Account Codes</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_acct_code', automation_id:'drp_acct_code' }"
                            value-expr="id"
                            display-expr="name"
                            :items="accountCodes"
                            :disabled="readOnly"
                            v-model="commission.accountingCodeID"
                        />
                    </div>
                </div>
                <div class="row">
                     <rq-action-form-group
                        :label="sellerRealtorLabel"
                        action-automation-id="btn_revert_listing_commission"
                        :hasError="listingAgentCommissionSplitUnbalanced"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="commission.listingAgentPayee.hasCommissionAmountOverride && !readOnly"
                        :info-tool-tip="listingAgentEarnestInfo"
                        class="col col-sm-12 col-lg"
                        @action-click="onRevertListingCommissionAmount(false)">
                            <div :class="{ 'input-group':true, 'has-error': listingAgentCommissionSplitUnbalanced }">
                            <span class="input-group-text">$</span>
                            <rq-input-number
                                id="txt_listing_commission_amount"
                                defaultValue="0"
                                minValue="0"
                                decimals="2"
                                value-event="input"
                                v-model="commission.listingAgentPayee.totalCommission"
                                prefix="$"
                                @change="onListingCommissionAmountInput"
                                :disabled="readOnly"
                            />
                            </div>
                            <rq-validation-feedback v-if="listingAgentCommissionSplitUnbalanced">Adjust Commission Split Flat Fee Amount</rq-validation-feedback>
                    </rq-action-form-group>
                    <div class="col-12 col-sm-12 col-lg-3 form-group">
                        <label class="form-control-label"
                            for="payee_company">Payee</label>
                        <company-picker
                            ref="refPayeeCompany"
                            automation_id="pic_Payee_company"
                            companyRoleName="Real Estate Agent"
                            :companyRoleId="10"
                            dialogTitle="Select Payee"
                            :disabled="readOnly"
                            v-model="commission.listingAgentPayee.company">
                        </company-picker>
                    </div>
                    <div class="col form-group">
                        <label for="txt_increase_amount">PAID BY</label>
                        <rq-radio-group
                            :inline="true"
                            v-model="commission.listingAgentPayee.isPaidByBuyer"
                            :options="[
                                { automation_id: 'radio_paid_by_buyer', value: true, text: 'Buyer'},
                                { automation_id: 'radio_paid_by_seller', value: false, text: 'Seller'}
                            ]"
                            />
                    </div>
                </div> 
                <div class="row">
                    <rq-action-form-group
                        :label="buyerRealtorLabel"
                        action-automation-id="btn_revert_total_commission"
                        :hasError="sellerAgentCommissionSplitUnbalanced"
                        action-label="Revert"
                        action-class="overridden"
                        :show-action="commission.sellingAgentPayee.hasCommissionAmountOverride && !readOnly"
                        :info-tool-tip="sellingAgentEarnestInfo"
                        class="col col-sm-12 col-lg"
                        @action-click="onRevertSellingCommissionAmount(false)">
                            <div :class="{ 'input-group':true, 'has-error': sellerAgentCommissionSplitUnbalanced }">
                            <span class="input-group-text">$</span>
                            <rq-input-number
                                id="txt_selling_commission_amount"
                                defaultValue="0"
                                minValue="0"
                                decimals="2"
                                value-event="input"
                                v-model="commission.sellingAgentPayee.totalCommission"
                                prefix="$"
                                @change="onSellingCommissionAmountInput"
                                :disabled="readOnly"
                            />
                            </div>
                            <rq-validation-feedback v-if="sellerAgentCommissionSplitUnbalanced">Adjust Commission Split Flat Fee Amount</rq-validation-feedback>
                    </rq-action-form-group>
                    <div class="col-12 col-sm-12 col-lg-3 form-group">
                        <label class="form-control-label"
                            for="payee_company">Payee</label>
                        <company-picker
                            ref="refSellingPayeeCompany"
                            automation_id="pic_Payee_company"
                            companyRoleName="Real Estate Agent"
                            :companyRoleId="10"
                            dialogTitle="Select Payee"
                            :disabled="readOnly"
                            v-model="commission.sellingAgentPayee.company">
                        </company-picker>
                    </div>
                    <div class="col form-group">
                        <label for="txt_increase_amount">PAID BY</label>
                        <rq-radio-group
                            :inline="true"
                            v-model="commission.sellingAgentPayee.isPaidByBuyer"
                            :options="[
                                { automation_id: 'radio_paid_by_buyer', value: true, text: 'Buyer'},
                                { automation_id: 'radio_paid_by_seller', value: false, text: 'Seller'}
                            ]"
                            />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="commissionDivisionGrid"
            automation_id="tbl_commission_division"
            :actions="commissionDivisionGridActions"
            :config="commissionDivisionGridConfig"
            :data-source="commissionDivisionGridDataSource"
            export-file-name="commission-division-data"
            v-model:validation-errors="validationErrors"
            hide-search
            hide-clear-filters
            focus-after-last-row="first-row"
            @delete="onDeleteCommissionDivision"
            :rq-editable="!readOnly"
            title="Commission Division">
            <template #toolbar>
                <ul class="nav me-auto">
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="readOnly">Add Division</button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_seller_division" @click="onAddSellerDivision">Seller Commission</button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" automation_id="add_buyer_division" @click="onAddBuyerDivision">Buyer Commission</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from '@/app.events';
    import { REALTOR_COMMISSION_ACTIONS } from "@/store/actions";
    import { RealtorCommissionDto, RealtorCommissionPayee, CommissionPayeeType, RealtorCommissionModel, CommissionType } from "./models.js";
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import { CompanyPicker } from '@/shared/components/rq';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { POCNetFundOptions, POCWhoIntOptions, SETTLEMENT_TYPE, SettlementTypeOption } from "@settlement/models";
    import { SettlementReport } from '@settlement/components/dashboard/models';
    import BaseSettlementMixin from "../../BaseSettlementMixin";

    export default {
        name: "RealtorCommission",
        mixins: [GridCompanyPickerMixin, GridSystemLookupMixin, BaseSettlementMixin],
        components: { CompanyPicker },
        data(){
            return {
                validationErrors:[],
                alertMessage: "",
                dataLoaded: false,
                commission: new RealtorCommissionDto(),
                selectedLoanId: 0,
                commissionItemKey: "commissionId",
                commissionItems:[],
                commissionGridDataSource: {},
                commissionGridConfig: {},
                commissionDivisionGridDataSource: {},
                commissionDivisionGridConfig: {},
                commissionSelectionActions: [],
                canAddDivisions:false,
                deletedPayees: [],
                listingAgentCommissionSplitUnbalanced: false,
                sellerAgentCommissionSplitUnbalanced: false,
                buyersAgentCommissionInvalid: false,
                splitPercentInvalid: false,
                showAgentCommissionInvalidBanner: false
            };
        },

        props: {
            displayMode: {type: String, default: "route"}
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "buyers",
                "sellers"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                order: state => state.orders.order,
                loans: state => state.orders.loans,
                originalCommission: state => state.realtorCommission.commission,
                splitCommissions: state => state.system.systemDefaults.hudSplitRealtorCommissions,
                splitCommissionsAmount: state => state.system.systemDefaults.hudSplitAmount,
                useAsSellingAgent: state => _.parseBool(state.system.systemDefaults.useBuyerRealtorAsSellingAgent),
                useAsListingAgent: state => _.parseBool(state.system.systemDefaults.useSellerRealtorAsListingAgent),
                defaultLoanId: state => state.orders.orderSummary.defaultLoanID,
                overrideAutoCalculate: state => state.system.systemDefaults.overrideAutoCalcCommission
            }),
            accountCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES, this.order.regionID, this.commission.accountingCodeID); },
            readOnly() { return this.$store.state.isPageReadOnly || !this.isFirstLoan; },
            isFirstLoan() { return (this.loans[0].loanID === this.defaultLoanId); },
            noPayeeData() { return this.dataLoaded && _.isEmpty(this.commission.payees); },
            showAlert() { return !_.isEmpty(this.alertMessage); },
            splitCommissionsAmount() { return this.$store.state.system.systemDefaults.hudSplitAmount; },
            sellerCommissionPayees() { return this.commission.filterItemsBy(CommissionPayeeType.Seller); },
            buyerCommissionPayees() { return this.commission.filterItemsBy(CommissionPayeeType.Buyer); },
            totalSellerCommission() { return this.commission.getItemTotal(CommissionPayeeType.Seller); },
            totalBuyerCommission() { return this.commission.getItemTotal(CommissionPayeeType.Buyer); },
            hasSellerCommissions() { return !this.noPayeeData && _.gt(this.sellerCommissionPayees.length, 0); },
            hasBuyerCommissions() { return !this.noPayeeData && _.gt(this.buyerCommissionPayees.length, 0); },
            buyerRealtorLabel () { return this.useAsSellingAgent ? "Commission - Selling Agent" : "Buyer's Real Estate Commission"; },
            sellerRealtorLabel() { return this.useAsListingAgent ? "Commission - Listing Agent" : "Seller's Real Estate Commission"; },
            secondaryPercentLabel() {return this.useAsSellingAgent ? "Selling Agent Gets" : "Buyer's Real Estate Agent Gets"; },
            commissionGrid(){ return _.get(this, "$refs.commissionGrid.gridInstance", null) || {}; },
            commissionDivisionGrid(){ return _.get(this, "$refs.commissionDivisionGrid.gridInstance", null) || {}; },
            commissionDivisionGridActions() {
                return [{ name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: false, tooltip: "Delete", disabled: this.readOnly  }];
            },
            selectedView() { return _.parseNumber(this.$store.state.orders.orderSummary.settlementStatementType, SETTLEMENT_TYPE.CDF); },
            gridCommMainStorageKey() { return `settlement_commission_main_${this.selectedView}`; },
            gridCommDivMainStorageKey() { return `settlement_commission_division_${this.selectedView}`; },
            SettlementTypes() { return SETTLEMENT_TYPE; },
            isCdf() { return this.selectedView === SETTLEMENT_TYPE.CDF; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            isHud1974() { return this.selectedView === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.selectedView === SETTLEMENT_TYPE.HUD_2010; },
            pocWhoOptions() { return POCWhoIntOptions.lookupItems; },
            buyerSellerMessage() {
                let noBuyer = _.isEmpty(this.buyers);
                let noSeller = _.isEmpty(this.sellers);
                if(!noBuyer && !noSeller) return "";
                let buyerSellerPart = noBuyer && noSeller ? "buyers or sellers" : noBuyer ? "buyers" : "sellers";
                return `No ${ buyerSellerPart } exist on the current file.`;
            },
            showBuyerSellerMessage() { return !_.isEmpty(this.buyerSellerMessage); },
            firstLoanMessage() { return !this.isFirstLoan ? "Commissions can only be edited when default loan is set to Loan 1." : ""; },
            showFirstLoanMessage() { return !_.isEmpty(this.firstLoanMessage); },
            reportOptions() { return SettlementReport.reportOptionsCommissionCheck(this.orderId, this.mapSettlementStatement); },
            listingAgentEarnestInfo() {
                return _.get(this, "commission.listingAgentPayee.isDeducted", false) && _.parseNumber(this.commission.listingAgentPayee.earnestMoney, 0) > 0
                            ? `${this.formatMoney(this.commission.listingAgentPayee.earnestMoney)} EMD will be deducted from Commission Check` : "";
            },
            sellingAgentEarnestInfo() {
                return _.get(this, "commission.sellingAgentPayee.isDeducted", false) && _.parseNumber(this.commission.listingAgentPayee.earnestMoney, 0) > 0
                            ? `${this.formatMoney(this.commission.sellingAgentPayee.earnestMoney)} EMD will be deducted from Commission Check` : "";
            },
            isDisplayModal() {
                return this.displayMode === "modal";
            }
        },

        watch: {
            originalCommission(newValue, oldValue) {
                let newValObj = _.cloneDeep(newValue);
                if(this.splitCommissions) newValObj.splitAtAmount = this.splitCommissionsAmount;
                this.commission = new RealtorCommissionDto(newValObj, this.splitCommissions);
                _.forEach(this.commissionItems, c => { c.data = this.commission });
            },
            "originalCommission.payees":function(newValue, oldValue) {
                this.commission.payees = _.map(newValue, p => new RealtorCommissionPayee(p));
            },
            selectedView(newValue, oldValue) {
                if (newValue === oldValue) return;
                this.onChangeSSViewSelected(newValue);
            }
        },

        mounted() {
            this.resetFocus();
        },

        created() {
            const self = this;

            if (!self.readOnly) {
                GlobalEventManager.onSave(self, self.onSaveClick);
                GlobalEventManager.onCancel(self, self.onCancelClick);
            }
            else {
                GlobalEventManager.onSave(self, () => {
                    GlobalEventManager.saveCompleted({ success: true });
                });
            }

            self.initSelectionActions();
            self.initGridConfig();
            self.fetchData();
            self.selectedLoanId = self.loans[0].loanID;
        },

        beforeUnmount () {
            GlobalEventManager.unregister(this);
        },

        methods:{

            initSelectionActions() {
                const self = this;
                self.commissionSelectionActions = [{
                        name: 'revert-amount',
                        eventName: 'revert-amount',
                        text: 'Revert',
                        allowMultiSelection: true,
                        disabled: function(e) {
                            if (self.readOnly) return self.readOnly;

                            return !_.some(e.data, item => item.hasCommissionAmountOverride || item.hasCommissionSplitAmountOverride)
                        },
                    }
                ];
            },

            initGridConfig(){
                const self = this;
                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    showContactPicker: false,
                };

                self.commissionDivisionGridConfig = {
                    focusedRowEnabled: false,
                    scrolling: { useNative: self.isDisplayModal },
                    columns: [
                        {
                            dataField: "realtorCommissionPayeeID",
                            visible: false,
                            allowEditing: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "payeeTypeID",
                            caption: "Payee Type",
                            showInColumnChooser: false,
                            groupIndex: 0,
                            groupCellTemplate: function(cellElement, cellInfo) {
                                let key = cellInfo.data.key;
                                let sumAmount = self.formatMoney(_.sumBy(cellInfo.data.items, 'commissionAmountOverride'));
                                let label = (key===CommissionPayeeType.Seller) ? "Seller Commissions" : "Buyer Commissions"
                                cellElement
                                    .append(`${label}`);
                                }
                        },
                        {
                            dataField: "payeeTypeID",
                            name: "description",
                            lookup: {
                                dataSource: CommissionType.lookupItems,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            caption: "Description"
                        },
                        //from GridCompanyPickerMixin
                        self.getCompanyContactGridColumn({
                            column: {
                                dataField: "companyName",
                                caption: "Payee"
                            },
                            ...payeePickerInfo
                        }),
                        {
                            dataField: "commissionAmountOverride",
                            format:{type: "currency", precision: 2},
                            editorOptions: { format: { type:"currency", precision: 2 } },
                            caption: "Amount",
                            width: 150
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "netFundType",
                                caption: "Net Fund",
                            },
                            lookupKey: self.lookupItems.NET_FUND
                        }),
                        // {
                        //     dataField: "netFundType",
                        //     lookup: {
                        //         dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.NET_FUND),
                        //         displayExpr: "name",
                        //         valueExpr: "id"
                        //     },
                        //     caption: "Net Fund"
                        // },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "accountingCodeID",
                                dataType: "number",
                                caption: "Acct Code",
                                editorOptions: { showClearButton: true },
                                width: 200
                            },
                            lookupKey: self.lookupItems.ACCOUNTING_CODES,
                            regionId: self.order.regionID,
                            customSort: function(i) { return _.parseNumber(_.get(i, "data")); }
                        }),
                        // {
                        //     dataField: "accountingCodeID",
                        //     lookup: {
                        //         dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.ACCOUNTING_CODES),
                        //         displayExpr: "name",
                        //         valueExpr: "id"
                        //     },
                        //     caption: "Acct Code",
                        //     width: 200
                        // },
                        {
                            dataField: "pocWhom",
                            caption: 'POC Whom',
                            editorOptions: {
                                searchMode: "startswith"
                            },
                            lookup: {
                                dataSource: self.pocWhoOptions,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            showInColumnChooser: self.isHud,
                            visible: self.isHud
                        },
                        {
                            dataField: "pocAmount",
                            caption: "POC Amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: { type: "currency", precision: 2 }
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            showInColumnChooser: self.isHud,
                            visible: self.isHud
                        },
                        {
                            dataField: "itemize",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            caption: "Itemize",
                            width: 100
                        },
                        {
                            dataField: "deductFromRealtorCheck",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            caption: "Deduct From Check",
                            width: 175
                        }
                    ],
                    storageKey: self.gridCommDivStorageKey,
                    summary: {
                        groupItems: [
                            {
                                column:"commissionAmountOverride",
                                summaryType: "sum",
                                valueFormat: "currency",
                                showInGroupFooter: true,
                                customizeText: function(data){ return self.formatMoney(data.value); },
                            },
                            {
                                column:"description",
                                showInGroupFooter: true,
                                displayFormat: "TOTALS"
                            },
                        ]
                    }
                };

                self.commissionDivisionGridDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.commission.payees);
                    },
                    update: self.onCommissionDivisionGridUpdate
                };
            },

            onCommissionDivisionGridUpdate(key, values) {
                const self = this;
                let item = _.find(self.commission.payees, c => c.clientKey === key);
                _.assign(item, values);
                return Promise.resolve(item);
            },

            onDivisionCommissionShown(e){
                const self = this;
                self.canAddDivisions = true;
                self.$nextTick().then(() => {
                    self.commissionDivisionGrid.updateDimensions();
                });
            },

            onDivisionCommissionHidden(e){
                this.canAddDivisions = false;
            },

            fetchData() {
                const self = this;
                let storePromise = self.$store.dispatch(REALTOR_COMMISSION_ACTIONS.GET_REALTOR_COMMISSION, self.orderId);
                return self.$rqBusy.wait(storePromise)
                    .then(result => {
                        self.dataLoaded = true;
                        self.commissionItems.length = 0;
                        self.commissionItems.push(new RealtorCommissionModel(self.commission, CommissionType.Seller, self.sellerRealtorLabel));
                        self.commissionItems.push(new RealtorCommissionModel(self.commission, CommissionType.Buyer, self.buyerRealtorLabel));
                        self.commissionDivisionGrid.clearSelection();
                        self.commissionDivisionGrid.refresh();
                        return true;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("Failed to retrieve commission information.");
                        return error;
                    });
            },

            revertAmount(commissionId, fieldName, refresh=false) {
                let item = _.find(self.commissionItems, c => c.commissionId === commissionId);
                item[`${fieldName}Override`] = item[fieldName];
                if(!refresh) return;
                this.refreshCommissionItems();
            },

            refreshCommissionItems(){
                _.each(this.commissionItems, c => c.loadFromSource());
            },

            onRevertCommissionAmountOverride(e) {
                const self = this;
                _.forEach(e.data, gc => {
                    let item = _.find(self.commissionItems, c => c.commissionId === gc.commissionId);
                    item.commissionAmountOverride = item.commissionAmount;
                    item.commissionSplitAmountOverride = item.commissionSplitAmount;
                    item.save();
                });
                this.refreshCommissionItems();
            },

            onRevertCommissionSplitAmountOverride(e) {
                const self = this;
                _.forEach(e.data, gc => {
                    let item = _.find(self.commissionItems, c => c.commissionId === gc.commissionId);
                    item.commissionSplitAmountOverride = item.commissionSplitAmount;
                    item.save();
                });
                this.refreshCommissionItems();
            },

            onRevertAmountOverrides(e) {
                const self = this;
                _.forEach(e.data, gc => {
                    let item = _.find(self.commissionItems, c => c.commissionId === gc.commissionId);
                    item.commissionAmountOverride = item.commissionAmount;
                    item.commissionSplitAmountOverride = item.commissionSplitAmount;
                    item.save();
                });
                this.refreshCommissionItems();
            },

            onCommissionTotalAmountInput(e) {
                this.commission.totalAmountOverridden = true;
                this.commission.totalAmountVal = e.value;
                this.commission.updateAmounts(this.splitCommissions);
                this.refreshCommissionItems();
            },

            onListingCommissionAmountInput(e) {
                const self = this;

                self.commission.listingAgentPayee.commissionAmountOverride = e.value;

                if (self.overrideAutoCalculate) {
                    self.autoCalculateFlatCommission(true);
                    self.validateAgentCommissions();
                }
            },

            onSellingCommissionAmountInput(e) {
                const self = this;
                self.commission.sellingAgentPayee.commissionAmountOverride = e.value;

                if (self.overrideAutoCalculate) {
                    self.autoCalculateFlatCommission(false);
                    self.validateAgentCommissions();
                }
            },

            onRevertListingCommissionAmount(e) {
                const self = this;
                self.commission.listingAgentPayee.commissionAmountOverride = self.commission.listingAgentPayee.commissionAmount;

                if (self.overrideAutoCalculate) {
                    self.autoCalculateFlatCommission(true);
                    self.validateAgentCommissions();
                }
            },

            onRevertSellingCommissionAmount(e) {
                const self = this;
                self.commission.sellingAgentPayee.commissionAmountOverride = self.commission.sellingAgentPayee.commissionAmount;

                if (self.overrideAutoCalculate) {
                    self.autoCalculateFlatCommission(false);
                    self.validateAgentCommissions();
                }
            },

            onTotalPercentChange(e) {
                this.commission.setTotalPercent();
                this.onPrimaryPercentChange(e);
                this.refreshCommissionItems();
                this.buyersAgentCommissionInvalid = this.isBuyersAgentPercentInvalid();
            },

            onTotalSplitPercentChange(e) {
                this.commission.setTotalSplitPercent();
                this.onPrimarySplitPercentChange(e);
                this.refreshCommissionItems();
                this.splitPercentInvalid = this.isSplitPercentInvalid();
            },

            // RQO-5549: HUD mapping dictates that POCWhom must be the same for selling/listing agent.
            onPOCWhomChange(value){
                _.forEach(this.commissionItems, c => {
                    c.pocWhom = value;
                })

                this.commission.listingAgentPayee.pocWhom = value;
                this.commission.sellingAgentPayee.pocWhom = value;
            },

            onPrimaryPercentChange(e) {
                this.balanceAgentPercent(this.commission.listingAgentPayee, this.commission.sellingAgentPayee);
            },

            onPrimarySplitPercentChange(e) {
                this.balanceAgentPercent(this.commission.listingAgentPayee, this.commission.sellingAgentPayee, true);
            },

            onSecondaryPercentChange(e) {
                this.commission.setTotalPercent();
                this.balanceAgentPercent(this.commission.sellingAgentPayee, this.commission.listingAgentPayee);
                this.buyersAgentCommissionInvalid = this.isBuyersAgentPercentInvalid();
            },

            onSecondarySplitPercentChange(e) {
                this.commission.setTotalSplitPercent();
                this.balanceAgentPercent(this.commission.sellingAgentPayee, this.commission.listingAgentPayee, true);
                this.splitPercentInvalid = this.isSplitPercentInvalid();
            },

            balanceAgentPercent(updatedAgent, balancingAgent, splitPercent=false) {
                let tp = splitPercent ? this.commission.totalSplitPercent : this.commission.totalPercent;
                let percentField = splitPercent ? "commissionSplitPercent" : "commissionPercent";
                let cp = updatedAgent[percentField];
                balancingAgent[percentField] = tp - cp;
                this.commission.updateAmounts(this.splitCommissions);
                this.refreshCommissionItems();
            },

            onSalesPriceChange(e) {
                if(e.target && e.target.value) {
                    this.commission.salesPriceOverride = e.target.value;
                }
                
                this.commission.updateAmounts(this.splitCommissions);
                this.refreshCommissionItems();
            },

            onSplitAtAmountChange(e) {
                this.commission.updateAmounts(this.splitCommissions);
                this.refreshCommissionItems();
            },

            onRevertSalesPrice(e) {
                this.commission.salesPriceOverride = this.commission.salesPrice;
                this.commission.updateAmounts(this.splitCommissions);
                this.refreshCommissionItems();
            },

            onRevertCommissionAmount(e) {
                this.commission.totalAmountOverridden = false;
                this.commission.totalAmountVal = this.commission.salesPriceOverride * this.commission.totalPercent/100;
                this.commission.updateAmounts(this.splitCommissions);
                this.refreshCommissionItems();
            },

            onRevertSplitAtAmount(e) {
                this.commission.splitAtAmountOverride = this.splitCommissionsAmount;
                this.commission.updateAmounts(this.splitCommissions);
                this.refreshCommissionItems();
            },

            onAddSellerDivision(e){
                this.addPayee(CommissionPayeeType.Seller);
            },
            onAddBuyerDivision(e){
                this.addPayee(CommissionPayeeType.Buyer);
            },
            onDeleteCommissionDivision(e){
                if(e.data.length === 0) return;
                const self = this;

                let items = e.data;

                let okHandler = function (args) {
                    self.deletePayees(items);
                    return true;
                }

                self.$dialog.confirm("Confirm Delete", "<p>Are you sure you want to delete selected realtor commission(s)?</p>", okHandler);
            },

            onChangeSSViewSelected(e) {
                // Following will set the proper columns per settlement view context
                let allowCdfOptions = _.parseBool(e === SETTLEMENT_TYPE.CDF);
                let allowHudOptions = !allowCdfOptions;

                // HUD fields
                // this.commissionGrid.columnOption("pocWhom", "visible", allowHudOptions);
                // this.commissionGrid.columnOption("pocWhom", "showInColumnChooser", allowHudOptions);

                // this.commissionGrid.columnOption("pocAmount", "visible", allowHudOptions);
                // this.commissionGrid.columnOption("pocAmount", "showInColumnChooser", allowHudOptions);


                // this.commissionDivisionGrid.columnOption("pocWhom", "visible", allowHudOptions);
                // this.commissionDivisionGrid.columnOption("pocWhom", "showInColumnChooser", allowHudOptions);

                // this.commissionDivisionGrid.columnOption("pocAmount", "visible", allowHudOptions);
                // this.commissionDivisionGrid.columnOption("pocAmount", "showInColumnChooser", allowHudOptions);

                // this.commissionGrid.refresh();
                // this.commissionDivisionGrid.refresh();

                this.initGridConfig();
                this.$nextTick().then(() => {
                    _.invoke(this, "$refs.commissionGrid.loadGrid");
                    _.invoke(this, "$refs.commissionDivisionGrid.loadGrid");
                })
            },

            isSplitPercentInvalid(){
                if(!this.splitPercentInvalid && !this.buyersAgentCommissionInvalid){
                    this.showAgentCommissionInvalidBanner =  false;
                }
                return this.commission.totalSplitPercent < this.commission.sellingAgentPayee.commissionSplitPercent;
            },

            isBuyersAgentPercentInvalid(){
                if(!this.splitPercentInvalid && !this.buyersAgentCommissionInvalid){
                    this.showAgentCommissionInvalidBanner =  false;
                }
                return this.commission.totalPercent < this.commission.sellingAgentPayee.commissionPercent;
            },

            onSaveClick(e) {
                let userInitiated = _.get(e, "userInitiated", false);
                if(this.listingAgentCommissionSplitUnbalanced || this.sellerAgentCommissionSplitUnbalanced || this.buyersAgentCommissionInvalid || this.splitPercentInvalid) {
                    this.showAgentCommissionInvalidBanner = true;
                    GlobalEventManager.saveCompleted({success: false});
                    return;
                }
                this.commissionDivisionGrid.saveEditData();
                this.save(userInitiated);
            },

            onCancelClick(e) {
                const self = this;
                if(!self.isDirty()) {
                    self.$toast.info("No changes detected.");
                    return;
                }
                let okHandler = () => { 
                    self.reset(); 
                    self.listingAgentCommissionSplitUnbalanced = false;
                    self.sellerAgentCommissionSplitUnbalanced = false;
                    self.buyersAgentCommissionInvalid = false;
                    self.splitPercentInvalid = false;
                    self.showAgentCommissionInvalidBanner =  false;
                };
                self.$dialog.confirm("Cancel Changes", "Are you sure you want to cancel and undo the current realtor commission changes?", okHandler);
            },

            addPayee(roleTypeId) {
                const self = this;
                self.commissionDivisionGrid.saveEditData()
                    .then(() => {
                        let itemDescription = roleTypeId === CommissionPayeeType.Buyer
                            ? self.buyerRealtorLabel
                            : self.sellerRealtorLabel;
                        let newItem = new RealtorCommissionPayee({
                            description: itemDescription,
                            payeeTypeID: roleTypeId,
                            deductFromRealtorCheck: true,
                            itemize: true
                        });
                        self.commission.payees.push(newItem);
                        self.commissionDivisionGrid.clearSelection();
                        self.commissionDivisionGrid.refresh();
                        _.delay(function(){ self.setRowEditAndFocusCompany(newItem.clientKey); }, 100);
                    });
            },

            setRowEditAndFocusCompany(key){
                const self = this;
                let rowIndex = self.commissionDivisionGrid.getRowIndexByKey(key);
                if(rowIndex < 0) return;
                let colIndex = _.parseNumber(self.commissionDivisionGrid.columnOption("companyName", "visibleIndex"), 0);
                _.invoke(this, "$refs.commissionDivisionGrid.setEditRow", rowIndex, colIndex);
            },

            deletePayees(items) {
                const self = this;

                _.forEach(items, (item) => {
                    let targetItemIndex = _.findIndex(self.commission.payees, i => item.clientKey === i.clientKey);
                    if(targetItemIndex >= 0) self.commission.payees.splice(targetItemIndex, 1);
                    if(item.realtorCommissionPayeeID === 0) {
                        self.commissionDivisionGrid.clearSelection();
                        self.commissionDivisionGrid.refresh();
                        return Promise.resolve(true);
                    }
                    item.isDeleted = true;
                    self.deletedPayees.push(item);
                    self.commissionDivisionGrid.clearSelection();
                    self.commissionDivisionGrid.refresh();

                    return true;
                });
            },

            save(userInitiated=false) {
                const self = this;

                if (self.readOnly) {
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                return Promise.all(
                    [self.commissionDivisionGrid.saveEditData()])
                        .then(() => {
                            if(!self.isDirty()) {
                                GlobalEventManager.saveCompleted({success: true});
                                if(!userInitiated) return Promise.resolve(true);
                                self.$toast.info("No changes detected.");
                                return Promise.resolve(true);
                            }

                            if(!self.isValid()){
                                GlobalEventManager.saveCompleted({success: false});
                                return Promise.resolve(true);
                            }
                            self.commission.payees = _.concat(self.commission.payees, self.deletedPayees);
                            let storePromise = self.$store.dispatch(REALTOR_COMMISSION_ACTIONS.SAVE_REALTOR_COMMISSION, self.commission.toDataObject());
                            let savePromise = userInitiated ? self.$rqBusy.wait(storePromise, { topLevel:true }) : storePromise;
                            return savePromise
                                .then((result) => {
                                    self.$toast.success("Real Estate Commissions saved successfully.");
                                    GlobalEventManager.saveCompleted({success: true});
                                    self.commissionDivisionGrid.clearSelection();
                                    self.commissionDivisionGrid.refresh();
                                    return true;
                                })
                                .catch((error) => {
                                    self.$toast.error("Real Estate Commissions save operation failed.");
                                    GlobalEventManager.saveCompleted({success: false});
                                });
                        })
                    .then(result => result);
            },

            reset() { this.fetchData(); },

            isValid() { return true; },

            isDirty() {
                //see if any new payees were added
                if(_.some(this.commission.payees, p => p.realtorCommissionPayeeID === 0)) return true;

                let changes = this.getCurrentChanges();
                return _.gt(changes.length, 0);
            },

            getCurrentChanges(){
                const self = this;
                let currentData = this.commission.toDataObject();
                let originalData = new RealtorCommissionDto(self.originalCommission, this.splitCommissions).toDataObject();
                return self.getAuditChanges(originalData, currentData);
            },

            onEditorPreparing(e) {
                const self = this;

                if(e.type === "selection" || e.row.rowType !== "data") return;

                e.editorOptions.disabled = self.isColumnDisabled(e.dataField, e.row.data);
            },

            isColumnDisabled(dataField, data) {
                const self = this;
                if (dataField == 'commissionPercent' || dataField == 'commissionSplitPercent'){
                    return data.commissionId === 2;
                }
                if (dataField == 'commissionAmountOverride'){
                    let commission = _.find(self.commissionItems, c => c.commissionId != data.commissionId);
                    return commission.hasCommissionAmountOverride;
                }
                if (dataField == 'commissionSplitAmountOverride'){
                    let commission = _.find(self.commissionItems, c => c.commissionId != data.commissionId);
                    return commission.hasCommissionSplitAmountOverride;
                }

                return false;
            },

            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"$0.00" } }); },

            parseFixed(v, d) { return accounting.parse(accounting.toFixed(v, d)); },

            resetFocus() { this.$refs.salesPriceInput.focus(); },

            autoCalculateFlatCommission(listingAgentCommissionChanged) {

                const self = this;
                if(self.commission.sellingAgentPayee.commissionPercent !== 0) {
                    return;
                }

                let totalCommission = self.commission.totalAmountVal;
                let commissionChanged = listingAgentCommissionChanged ? 
                    self.commission.listingAgentPayee.commissionAmountOverride : self.commission.sellingAgentPayee.commissionAmountOverride;
                
                if(commissionChanged > totalCommission)
                    return;

                let remainingCommission = totalCommission - commissionChanged;
                if(listingAgentCommissionChanged) {
                    self.commission.sellingAgentPayee.commissionAmountOverride = remainingCommission; 
                }
                else {
                    self.commission.listingAgentPayee.commissionAmountOverride = remainingCommission;
                }
            },

            validateAgentCommissions() {
                const self = this;
                let totalCommission = self.commission.totalAmountVal;
                let sellingAgentPayeeCommission = self.commission.sellingAgentPayee.hasCommissionAmountOverride? 
                                                    self.commission.sellingAgentPayee.commissionAmountOverride : 
                                                    (self.commission.sellingAgentPayee.commissionAmount + self.commission.sellingAgentPayee.commissionSplitAmountOverride);
                let listingAgentPayeeCommission = self.commission.listingAgentPayee.hasCommissionAmountOverride? 
                                                    self.commission.listingAgentPayee.commissionAmountOverride : 
                                                    (self.commission.listingAgentPayee.commissionAmountOverride + self.commission.listingAgentPayee.commissionSplitAmountOverride);
                let totalAgentCommissions = sellingAgentPayeeCommission + listingAgentPayeeCommission;
                self.sellerAgentCommissionSplitUnbalanced = self.commission.sellingAgentPayee.hasCommissionAmountOverride && (totalAgentCommissions != totalCommission);
                self.listingAgentCommissionSplitUnbalanced = self.commission.listingAgentPayee.hasCommissionAmountOverride && (totalAgentCommissions != totalCommission);
            }
        }
    };
</script>

<style lang="scss">
    .realtor-commission {
        .col-opr {
            max-width: 100px;
            > span {
                display: block;
                padding-top: 21px;
                width: 30px;
                text-align: center;
                font-size: 1.75rem;
                line-height: 1.25;
            }
        }
    }
</style>
