<template>
    <div id="closing-main" class="rq-container">
        <rq-banner
            variant="error"
            message="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="hasErrors"
            sticky
        />
        <rq-page-section title="Signing" headerSize="lg" borderless>
            <template #header-actions>
                <ul class="nav config-actions">
                    <li class="nav-item">
                        <b-button
                            automation_id="btn_add_closing"
                            :disabled="readOnly || getAreNonPaperSigningsComplete()"
                            variant="theme"
                            @click="onAdd"
                            >Add
                        </b-button>
                    </li>
                </ul>
                <div class="row mb-1">
                    <div class="col-auto ms-auto">
                        <rq-section-expand-collapse-all
                            section-group="closing-group"
                        />
                    </div>
                    <div v-if="pavasoEnabled && items.length > 0" 
                        class="col-auto ms-auto" 
                        v-rq-tooltip.hover.top="{ title: pavasoPartyRoleIdentifier }">
                            <FontAwesomeIcon icon="fas fa-info-circle" class="text-dark" />
                    </div>
                </div>
            </template>
        </rq-page-section>
        <rq-section-card
            :title="getDescriptionTitle(item.description)"
            section-group="closing-group"
            collapsible
            v-for="(item, index) in items"
            :key="item"
        >
            <div class="row">
                <div class="col col-auto ms-auto">
                    <ul class="nav">
                        <li>
                             <b-btn
                                variant="link"
                                @click="onDelete(item)"
                                :disabled="getIsSigningComplete(item)">
                                Delete
                            </b-btn>
                        </li>
                        <li v-rq-tooltip="{ title: signingButtonToolTipText(item) , placement: 'top', triggers: 'hover', boundary: 'window', disabled: item.signingEnabled}" class="nav-item" v-if="pavasoEnabled">
                            <b-btn
                                automation_id="btn_pavaso_deeplink"
                                :disabled="readOnly || !item.signingEnabled"
                                variant="theme"
                                @click="signDocuments(item)"
                                >Start Signing
                            </b-btn>
                        </li>
                    </ul>
                </div>
            </div>
            <signing-form
                :ref="'signingForm-' + index"
                v-model:item="items[index]"
                :fileScanDocuments="fileScanDocuments"
                :isUniqueSigningDate="isUniqueSigningDate"
                @open-tagging-session="openTaggingSession"
                :isComplete="getIsSigningComplete(item)"
            />
        </rq-section-card>
    </div>
</template>

<script>
import { computed } from "vue";
import { mapState, mapGetters } from "vuex";
import { OrderSigning } from "../models";
import { GlobalEventManager } from "@/app.events";
import SigningForm from "./SigningForm.vue";
import { ORDER_ACTIONS } from '@/store/actions';
import { useLicenseStore } from "@/store/modules/license";
import { FileScanDocumentDto }  from "../../../document-mgmt/models";
import { PavasoTaggingWrapper } from "@/integrations";
import { DateTime } from "luxon";
import { SigningDocumentStatus } from "../enum";
export default {
    name: "Signings",
    components: { SigningForm },
    data() {
        return {
            items: [],
            fileScanDocuments: [],
            originalData: [],
            deletedItems: [],
            hasErrors: false,
            pavasoPartyRoleIdentifier: ""
        };
    },
    setup(){
         const licenseStore = useLicenseStore();

        const pavasoEnabled = computed(() => licenseStore.features?.pavasoDigitalSignings);

        return {
            pavasoEnabled
        };
    },
    created() {
        const self = this;
        GlobalEventManager.onSave(self, self.onSave);
        GlobalEventManager.onCancel(self, self.onCancel);
        self.fetchData();
    },
    computed: {
        ...mapState({
            readOnly: state => state.isPageReadOnly,
            order: state => state.orders.order
        }),
        orderId() {
            return _.getNumber(this, "$route.params.orderId", 0);
        },
        hasSignings(){
            return this.originalData?.length > 0;
        },
        ...mapGetters(["lookupHelpers", "lookupItems"]),
        startSigningTooltip: '',
        signingStatuses() {
            return _.filter(
                this.lookupHelpers.getAllLookupItems(
                    this.lookupItems.SIGNING_STATUSES
                ),
                (item) => item.inactive != true
            );
        },
        signingTypes() {
            return _.filter(
                this.lookupHelpers.getAllLookupItems(
                    this.lookupItems.CLOSING_TYPES
                ),
                (item) => item.inactive != true
            );
        },
    },
    watch: {
        items: {
            handler(newVal, oldVal) {
                const self = this;
                newVal.forEach((newItem, index) => {
                    newItem.signingEnabled = this.canSignOrderSigning(newItem);
                });
            },
            deep: true
        }
    },
    beforeUnmount() {
        GlobalEventManager.unregister(this);
    },
    methods: {
        async openTaggingSession(document){
            const self = this;

            let dialogID = null;

            function onClickBackButton (){
                self.$dialog.close(dialogID);
            }

            let tokenPromise = self.$api.OrderSigningApi.getPavasoTaggingToken();
            let tokenResult = await self.$rqBusy.wait(tokenPromise);

            if (document.orderSigningDocumentStatusID == SigningDocumentStatus.NeedsReview) {
                document.orderSigningDocumentStatusID = SigningDocumentStatus.InProgress;
                let apiPromise = self.$api.OrderSigningApi.updateOrderSigningDocument(document);
                await self.$rqBusy.wait(apiPromise);
                self.fetchData();
            }

            const taggingFragmentConfig = {
                documentName: document.description,
                orderGuid: self.order.pavasoOrderGuid,
                isOpen: true,
                token: tokenResult.token,
                refreshToken: tokenResult.refreshToken,
                onClickBackButton: onClickBackButton
            };

            dialogID = self.$dialog.open({                    
                title: `Pavaso Tagging`,
                height: "95%",
                width: "95%",
                cancelTitle: "Close",
                component: PavasoTaggingWrapper,
                cancelOnly: true,
                scrollable: false,
                props: {
                    integrationConfig: taggingFragmentConfig
                }
            });
        },
        getDescriptionTitle(description){
            return _.isEmpty(description) ? '(No Description)' : description;
        },
        onCancel() {
            const self = this;
            if (self.$rqBusy.isBusy()) return;
            if(!self.hasChanges()) {
                self.$toast.info("No changes detected.");
                return;
            }
            self.$dialog.confirm("Confirm", "Discard changes and reload data?", () => {
                self.fetchData()
            });
        },
        onSave(e) {
            this.save(e?.userInitiated);
        },
        onAdd(e) {
            const self = this;
            let options = {
                ordersID: self.orderId,
                orderSigningID: _.parseNumber(_.uniqueId()) * -1,
                signingStatusID: _.find(self.signingStatuses, y => y.name === 'New').id,
            };
            self.items.push(new OrderSigning(options));
        },
        onDelete(item) {
            const self = this;
            self.items = _.filter(self.items, i => i.orderSigningID !== item.orderSigningID);
            self.deletedItems.push(item);
        },

        async signDocuments(orderSigning){
            const self = this;
            try {
                if(!orderSigning.signingEnabled)
                {
                    self.$toast.error("At least one document and one attendee (with name and email) is required.");
                    return;
                }

                await self.save(false);

                if (self.hasErrors) return;

                await self.savePavasoOrder();

                let signingStatusPromise = self.$api.OrderSigningApi.getSigningSessionInProgress(self.order.pavasoOrderGuid);
                let signingStatus = await self.$rqBusy.wait(signingStatusPromise);

                if(signingStatus){
                    self.$toast.error("Another signing for this file is already in progress.");
                    return;
                }

                let savePavasoOrderPromise = self.$api.OrderSigningApi.getPavasoDeepLink(orderSigning.orderSigningID);
                let signingDeeplink = await self.$rqBusy.wait(savePavasoOrderPromise);

                if(!signingDeeplink){
                    self.$toast.error("An issue occurred while generating Pavaso signing link.");
                }
                else{
                    window.open(signingDeeplink,'_blank');
                }
                let apiPromise = self.$api.OrderSigningApi.getOrderSignings(
                    self.orderId
                );
                let result = await self.$rqBusy.wait(apiPromise);
                self.items = _.map(result, (s) => new OrderSigning(s));
                let currentStatus = _.find(self.signingStatuses, x => x.id == self.items[0].signingStatusID);
                if(currentStatus?.name == 'Complete') {
                    self.$toast.error("Signing is complete, unable to save.");
                    return;
                }
            } catch (err) {
                GlobalEventManager.saveCompleted({ success: false });
                self.$toast.error("An issue occurred while generating Pavaso signing link.");
                console.error(err);
            }
        },
        signingButtonToolTipText(item){
            return this.getIsSigningComplete(item) ? 'Electronic signings are complete' : 
                !this.canSignOrderSigning(item) ? 'Must have file title company, closing agent, closing date, signing date & time, attendee, document and full property address' :
                "";
        },
        async savePavasoOrder(){
            const self = this;
            try {

                let savePavasoOrderPromise = self.$api.OrderSigningApi.savePavasoOrder(self.orderId);
                await self.$rqBusy.wait(savePavasoOrderPromise);

                await this.$store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, { refresh: true });

                self.$toast.success("Saved Pavaso Order Successfully.");
                GlobalEventManager.saveCompleted({ success: true });
            } catch (err) {
                GlobalEventManager.saveCompleted({ success: false });
                self.$toast.error("An issue occurred while saving Pavaso Order");
                console.error(err);
            }
        },

        async fetchData() {
            const self = this;
            try {
                let apiPromise = self.$api.OrderSigningApi.getOrderSignings(
                    self.orderId
                );
                let result = await self.$rqBusy.wait(apiPromise);
                self.items = _.map(result, (s) => new OrderSigning(s));
                self.originalData = _.map(result, (s) => new OrderSigning(s));
                self.deletedItems = [];

                let apiDocumentsPromise = self.$api.FileScanApi.getFileScanDocuments(self.orderId);
                var documentsResult = await self.$rqBusy.wait(apiDocumentsPromise);
                self.fileScanDocuments = _.uniqBy(_.map(documentsResult, i => new FileScanDocumentDto(i)), 'fileScanDocumentID');
            } catch (err) {
                self.$toast.error(
                    "An issue occurred while retrieving Order Signings."
                );
                console.error(err);
            }

            try {
                if(_.isEmpty(self.pavasoPartyRoleIdentifier)) {
                    let partyRoleIdentifierOutput = await self.$rqBusy.wait(self.$api.OrderSigningApi.getPavasoPartyRoleIdentifier(
                        self.orderId
                    ));
                    self.pavasoPartyRoleIdentifier = `Horizon Pavaso PRID ${partyRoleIdentifierOutput}`;
                }
            } catch(err) {
                console.error(err);
            }
        },

        async save(userInitiated) {
            const self = this;
            try {
                if (self.readOnly) return true;
                if (!self.hasChanges()) {
                    if(userInitiated) self.$toast.info({ message: "No changes detected" });
                    GlobalEventManager.saveCompleted({ success: true });
                    return;
                }

                self.hasErrors = false;

                _.forEach(self.items, (item, index) => {
                    const signingForm = self.$refs["signingForm-" + index][0];

                    if (signingForm.touch()) {
                        self.hasErrors = true;
                    }
                });

                if (self.hasErrors) {
                    GlobalEventManager.saveCompleted({success: false});
                    return;
                }

                let saveItems = self.items;
                let savePromise = self.$api.OrderSigningApi.saveDeleteRecords(
                    saveItems,
                    self.deletedItems
                );

                await self.$rqBusy.wait(savePromise);

                self.$toast.success("Saved Signings Successfully.");
                if(userInitiated) await self.fetchData();
                GlobalEventManager.saveCompleted({ success: true });
            } catch (err) {
                GlobalEventManager.saveCompleted({ success: false });
                self.$toast.error("An issue occurred while saving Signings");
                console.error(err);
            }
        },
        getIsSigningComplete(item){
            const self = this;
            let currentStatus = _.find(self.signingStatuses, x => x.id === item.signingStatusID)
            if (currentStatus?.name == 'Complete')
            {
                return true
            } else { return false }
        },
        getAreNonPaperSigningsComplete()
        {
            const self = this;
            let paperTypeID = _.find(self.signingTypes, y => y.name === 'Paper')?.id
            let completeStatusID = _.find(self.signingStatuses, y => y.name === 'Complete')?.id;
            let nonPaperSignings = _.filter(self.items, x => x.signingTypeID !== paperTypeID);

            if(nonPaperSignings.length == 0) return false;

            let areNonPaperSigningsComplete = !_.some(nonPaperSignings, y => y.signingStatusID !== completeStatusID);
            return areNonPaperSigningsComplete;
        },
        hasChanges() {
            //if the arrays are different length, then there are changes.  Deletes have already been saved
            if (this.items.length !== this.originalData.length) {
                return true;
            }
            //need to compare the two arrays for changes
            let changes = this.getAuditChanges(this.originalData, this.items);
            return changes.length > 0;
        },
        isValidSigningType(id){
            return _.includes([1, 3, 4, 5], id);
        },
        isPropertyInfoComplete(orderSigning){
            return !_.isEmpty(orderSigning.address1) && !_.isEmpty(orderSigning.city) && !_.isEmpty(orderSigning.state) && !_.isEmpty(orderSigning.zip) && (orderSigning.zip.length === 5 || orderSigning.zip.length === 10);
        },
        canSignOrderSigning(orderSigning){
            const self = this;

            let hasDocuments = orderSigning.orderSigningFileScanDocuments?.length > 0;
            let validAttendees = _.filter(orderSigning.orderSigningRoles, r => !_.isNil(r.contact) && !_.isEmpty(r.contact) && !_.isNil(r.email) && !_.isEmpty(r.email));

            let validSigningType = self.isValidSigningType(orderSigning.signingTypeID);//IPEN, Remote On-line Notarization, Multi Device CLosing, Remote Ink Notorization
            
            
           return !self.getIsSigningComplete(orderSigning) && 
                orderSigning.orderSigningID !== 0 && 
                validSigningType && 
                hasDocuments && 
                validAttendees?.length > 0 && 
                !_.isNil(self.order.closeDate) && 
                !_.isNil(self.order.titleCompanyCompanyID) &&
                !_.isNil(self.order.closeAgentStaffID) &&
                !_.isNil(orderSigning.signingDate) &&
                this.isPropertyInfoComplete(orderSigning);
        },
        isUniqueSigningDate(id, value, signingTypeID) {
            return _.isNil(signingTypeID) 
                || !this.isValidSigningType(signingTypeID) 
                || _.isEmpty(value) 
                || _.every(this.items, s => 
                    _.isEmpty(s.signingDate) 
                    || _.isNil(s.signingTypeID) 
                    || !this.isValidSigningType(s.signingTypeID) 
                    || s.orderSigningID == id 
                    || !DateTime.fromISO(s.signingDate).toUTC().hasSame(DateTime.fromISO(value).toUTC(), "minute"));
        }
    },
};
</script>
