const signingDocumentStatus = {
    NeedsReview: 1,
    InProgress: 2,
    Completed: 3
};

export class SigningDocumentStatus {
    static get NeedsReview() { return signingDocumentStatus.NeedsReview; }
    static get InProgress() { return signingDocumentStatus.InProgress; }
    static get Completed() { return signingDocumentStatus.Completed; }

    // Returns a list of all status lookup items
    static lookupItems() {
        return _.map(signingDocumentStatus, (status, key) => {
            return { id: status, name: signingDocumentStatus.displayValue(status) };
        });
    }

    // Display the appropriate label based on the status id
    static displayValue(id) {
        switch (id) {
            case signingDocumentStatus.NeedsReview:
                return "Needs Review";
            case signingDocumentStatus.InProgress:
                return "In Progress";
            case signingDocumentStatus.Completed:
                return "Completed";
        }
        return "";
    }
}